import React, { useCallback, useContext, useMemo } from 'react'
import { Grid, LinearProgress, styled, useMediaQuery } from '@mui/material'
import { TabContext } from './App'
import DashboardCarousal from '../../component/DashboardCarousal/DashboardCarousal'
import CategoryNavigation from '../../component/BottomCategoryNavigation/CategoryNavigation'
import TopHeader from '../../component/0_Common/TopHeader'
import { Outlet } from 'react-router-dom'
import { useTheme } from '@emotion/react'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import Footer from '../0_Common/Footer'
import { useWebSocketSelector } from '../../hooks/useWebsocket'

const PaddingGrid = styled(Grid)({ padding: '0' })

const MemoizedTopHeader =TopHeader
const MemoizedSideBarLeft = React.memo(SideBarLeft)
const MemoizedDashboardCarousal = React.memo(DashboardCarousal)
const MemoizedFooter = React.memo(Footer)
const MemoizedCategoryNavigation = React.memo(CategoryNavigation)

export const RootLayout = React.memo(() => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const status = useWebSocketSelector((state) => state.websocket.status)
  const { currentTab, setCurrentTab } = useContext(TabContext)

  const isLoading = status === 'CONNECTING'
  const hasError = status === 'ERROR' || status === 'CLOSED'

  const updateTab = useCallback(
    (newValue) => {
      setCurrentTab(newValue)
    },
    [setCurrentTab],
  )

  const mobileLayout = useMemo(() => (
    <>
      <div>
        <MemoizedTopHeader />
      </div>
      <PaddingGrid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: '0.5em', marginBottom: '' }}>
          <Outlet />
          <MemoizedFooter/>
        </Grid>
      </PaddingGrid>
      <Grid item xs={12}>
        <MemoizedCategoryNavigation currentTab={currentTab} updateTab={updateTab} />
      </Grid>
    </>
  ), [currentTab, updateTab])

  const desktopLayout = useMemo(() => (
    <>
         <MemoizedTopHeader />
      <PaddingGrid container spacing={3}>
       
        <Grid item lg={2.5} >
          <MemoizedSideBarLeft />
        </Grid>
        <Grid item lg={6.5} sx={{ marginTop: 0 }}>
          <Outlet />
          <MemoizedFooter/>
        </Grid>
        <Grid item lg={3}>
          <MemoizedDashboardCarousal />
        </Grid>
      </PaddingGrid>
    </>
  ), [])

  console.log('rendering root layout')

  return (
    <>
      {isLoading && (
        <div style={{ width: '100%' }}>
          <LinearProgress />
        </div>
      )}
      {hasError && (
        <div>Connection error. Please check your internet connection.</div>
      )}
      {!isDesktop ? mobileLayout : desktopLayout}
    </>
  )
})