// Safely access environment variables with fallbacks
const REACT_APP_BASE_URL = window.ENV_CONFIG?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || '';
const REACT_APP_WEBSOCKET_HOST = window.ENV_CONFIG?.REACT_APP_WEBSOCKET_HOST || process.env.REACT_APP_WEBSOCKET_HOST || '';

export const loginUrl = `${REACT_APP_BASE_URL}/user/login`;
export const otpUrl = `${REACT_APP_BASE_URL}/user/send-otp`;
export const betPlaceUrl = `${REACT_APP_BASE_URL}/bet/market-bet`;
export const betPlaceFancyUrl = `${REACT_APP_BASE_URL}/bet/fancy-bet`;
export const balanceURL = `${REACT_APP_BASE_URL}/user/balance`;
export const AccountStatementUrl = `${REACT_APP_BASE_URL}/account/getStatement`
export const BetHistoryUrl = `${REACT_APP_BASE_URL}/bet/bet-history`
export const UpdatePasswordUrl = `${REACT_APP_BASE_URL}/user/update-password`
export const BannerListUrl = `${REACT_APP_BASE_URL}/banner/list`

//Socket
export const webSocketURL = `${REACT_APP_WEBSOCKET_HOST}/api/socket`;