import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  styled,
  useMediaQuery,
  useTheme,
  Collapse
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ProfitLossStatement = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const FilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const StatementCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const StatementRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const PnlText = styled(Typography)(({ theme, pnl }) => ({
  color: pnl >= 0 ? theme.palette.success.main : theme.palette.error.main,
}));

const ProfitLossPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDetails, setOpenDetails] = useState({});

  const mockData = {
    "4": {
        "name": "Cricket",
        "order": 1,
        "totalPnl": -1225,
        "data": [
            {
                "memberId": 6466046,
                "eventTypeId": "4",
                "marketId": "1.231087735",
                "eventId": "33445158",
                "eventName": "Northern Superchargers v Southern Brave",
                "marketName": "Match Odds",
                "memberWin": -1225,
                "memberComm": 0,
                "marketTime": 1722341100000,
                "settledTime": 1722370945000,
                "pnl": -1225,
                "providerId": 1
            }
        ]
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate()} ${date.toLocaleString('default', { month: 'long' })} ${date.getFullYear()}`;
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const toggleDetails = (eventId) => {
    setOpenDetails(prev => ({ ...prev, [eventId]: !prev[eventId] }));
  };

  const renderProfitLossCard = (sportData) => (
    <StatementCard key={sportData.name}>
      <StatementRow>
        <Typography variant="subtitle1">{formatDate(sportData.data[0].settledTime)}</Typography>
        <PnlText variant="subtitle1" pnl={sportData.totalPnl}>
          P&L: {sportData.totalPnl}
        </PnlText>
      </StatementRow>
      {sportData.data.map((event) => (
        <Box key={event.eventId}>
          <StatementRow onClick={() => toggleDetails(event.eventId)} style={{ cursor: 'pointer' }}>
            <Typography variant="body1">{event.eventName}</Typography>
            {openDetails[event.eventId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </StatementRow>
          <Collapse in={openDetails[event.eventId]}>
            <DetailRow>
              <Typography variant="body2">Start Time: {formatDate(event.marketTime)} {formatTime(event.marketTime)}</Typography>
            </DetailRow>
            <DetailRow>
              <Typography variant="body2">Settled Time: {formatDate(event.settledTime)} {formatTime(event.settledTime)}</Typography>
            </DetailRow>
            <DetailRow>
              <Typography variant="body2">Comm:</Typography>
              <Typography variant="body2">{event.memberComm}</Typography>
            </DetailRow>
            <DetailRow>
              <Typography variant="body2">Net Win:</Typography>
              <PnlText variant="body2" pnl={event.pnl}>{event.pnl}</PnlText>
            </DetailRow>
          </Collapse>
        </Box>
      ))}
    </StatementCard>
  );

  return (
    <ProfitLossStatement>
      <Header>
        <Typography variant="h6">Betting Profit & Loss</Typography>
      </Header>
      <FilterButton startIcon={<FilterListIcon />}>
        Filter
      </FilterButton>
      {Object.values(mockData).map(renderProfitLossCard)}
    </ProfitLossStatement>
  );
};

export default ProfitLossPage;