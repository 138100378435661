import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const ExclusionPolicy = () => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Self Exclusion
        </Typography>

        <Typography variant="h5" gutterBottom>
          What is a self-exclusion?
        </Typography>
        <Typography paragraph>
          Self-exclusion is a facility that the Site offers to help those customers who feel that their gambling is out of control and want us to help them stop. By entering into a self-exclusion agreement with the Site, you will be prevented from using your Account (as defined in the terms and conditions) for a specific period, as determined by you, of between 6 months and 5 years.
        </Typography>

        <Typography variant="h5" gutterBottom>
          How to self-exclude from the Site
        </Typography>
        <Typography paragraph>
          If at any time you should you wish to exclude yourself from use of the Site (as defined in the terms and conditions), you must submit this request by email to <Box component="span" sx={{ fontWeight: 'bold' }}>[email address]</Box>.
        </Typography>
        <Typography paragraph>
          Please inform us of the period for which you wish to self-exclude. The minimum is 6 months and the maximum is 5 years. If you request self-exclusion but do not specify a period, we will exclude you for the minimum period of six months ("Minimum Period").
        </Typography>

        <Typography variant="h5" gutterBottom>
          How soon after requesting a self-exclusion will it be activated?
        </Typography>
        <Typography paragraph>
          We will endeavour to apply your exclusion as soon as practically possible. Normally, we will be able to reset your password to prevent you accessing the Site within 24 hours of your request.
        </Typography>

        <Typography variant="h5" gutterBottom>
          What happens if I self-exclude?
        </Typography>
        <Typography paragraph>
          We will make all reasonable efforts to:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Prevent any marketing material being forwarded to you;" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Remove you from any marketing databases operated by us;" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Suspend your activity by cancelling your ability to access the Site for the period requested by you, or if no period is requested by you, for the Minimum Period;" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckCircleOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText primary="Permanently close your Customer Account if instructed to do so by you, and return all funds owed to you to your nominated bank account." />
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Can I re-activate my Account or open a new Account during the self-exclusion period?
        </Typography>
        <Typography paragraph>
          Accounts that have been self-excluded cannot be reactivated under any circumstances until the expiry of the self-exclusion period.
        </Typography>
        <Typography paragraph>
          During the period of your exclusion, you must not attempt to re-open any existing Account(s), seek to open any new Accounts or seek to place bets through any other customer's Account.
        </Typography>

        <Typography variant="h5" gutterBottom>
          If I would like to re-activate my Account, is this possible?
        </Typography>
        <Typography paragraph>
          At the end of the self-exclusion period, you must contact us in person and confirm such intention in writing. If it is decided (in the Site's absolute discretion) to permit you to re-open your Account/open a new Account, you should be aware that a 24-hour waiting period will be imposed prior to the Account being available for use.
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Box display="flex" alignItems="center">
          <EmailIcon color="primary" sx={{ mr: 2 }} />
          <Typography variant="h6">
            Subscribe to notifications
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ExclusionPolicy;