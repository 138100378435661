import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  styled,
  Modal,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { AccountStatementUrl } from '../../constants';

const AccountStatement = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
}));

const Header = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bgColor,
  color: 'white',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const FilterButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

const StatementCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderLeft: `4px solid ${theme.palette.bgColor}`,
}));

const StatementRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));

const PnlText = styled(Typography)(({ theme, pnl }) => ({
  color: pnl >= 0 ? theme.palette.success.main : theme.palette.error.main,
  display: 'flex',
  alignItems: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
}));

const DetailModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const DetailContent = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(4),
  width: '90%',
  maxWidth: 800,
  maxHeight: '90vh',
  overflow: 'auto',
}));
// Configuration object
const config = {
  statementFields: [
    { key: 'date', label: 'Date', formatter: (value) => formatDate(value) },
    { key: 'time', label: 'Time', formatter: (value) => formatTime(value) },
    { key: 'actionDisplayName', label: 'Description' },
    { key: 'pnl', label: 'P&L', formatter: (value) => Math.abs(value).toFixed(2), component: PnlText },
    { key: 'creditLimit', label: 'Credit Limit', formatter: (value) => value === null ? '-' : value },
  ],
  detailFields: [
    'Selection', 'Status', 'Bet ID', 'Placed', 'Type', 'Odds', 'Stake', 'Profit/Loss'
  ],
  summaryFields: [
    { key: 'backSubtotal', label: 'Back subtotal' },
    { key: 'laySubtotal', label: 'Lay subtotal' },
    { key: 'marketSubtotal', label: 'Market subtotal' },
    { key: 'commission', label: 'Commission' },
    { key: 'netMarketTotal', label: 'Net Market Total', isTotal: true },
  ],
};

// Helper functions
const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
};

const generateMockDetailData = (item) => {
  const totalBets = Math.floor(Math.random() * 3) + 1; // 1 to 3 bets
  let remainingPnl = item.pnl;
  const detailData = [];

  for (let i = 0; i < totalBets; i++) {
    const isLastBet = i === totalBets - 1;
    const type = Math.random() > 0.5 ? 'Back' : 'Lay';
    const odds = (Math.random() * 2 + 1).toFixed(2);
    const stake = isLastBet ? Math.abs(remainingPnl) : Math.floor(Math.abs(remainingPnl) * (Math.random() * 0.8 + 0.2));
    const profitLoss = isLastBet ? remainingPnl : (type === 'Back' ? 1 : -1) * stake;

    detailData.push({
      selection: item.description.eventName.split(' v ')[0], // Use the first team as selection
      status: profitLoss >= 0 ? 'WON' : 'LOST',
      betId: `${item.date}${i + 1}`,
      placed: item.date,
      type,
      odds: parseFloat(odds),
      stake,
      profitLoss
    });

    remainingPnl -= profitLoss;
  }

  return detailData;
};
export const  mockData = [
  {
    date: 1722370945000,
    orderId:1211,
    actionDisplayName: "Betting P&L",
    action: "SETTLE",
    description: {
      market: "1.231087735",
      marketName: "Match Odds",
      marketTime: "2024-07-30T17:35:00",
      eventName: "Northern Superchargers v Southern Brave",
      eventTypeId: "4"
    },
    pnl: -1225,
    creditLimit: null,
    balance: 10227.35,
    commission: 0
  },
  {
    date: 1722198145000,
    orderId:1212,
    actionDisplayName: "Betting P&L",
    action: "SETTLE",
    description: {
      market: "1.231087736",
      marketName: "Match Odds",
      marketTime: "2024-07-29T19:24:00",
      eventName: "Evolution",
      eventTypeId: "4"
    },
    pnl: 0,
    creditLimit: null,
    balance: 11452.35,
    commission: 0
  },
  {
    date: 1722025345000,
    orderId:1213,
    actionDisplayName: "Betting P&L",
    action: "SETTLE",
    description: {
      market: "1.231087737",
      marketName: "Match Odds",
      marketTime: "2024-07-27T23:55:00",
      eventName: "Vancouver Knights v Mississauga Bangla Tigers",
      eventTypeId: "4"
    },
    pnl: -34780,
    creditLimit: null,
    balance: 46232.35,
    commission: 0
  }
];
const AccountStatementPage = () => {

 const navigate = useNavigate()
  const [openFilter, setOpenFilter] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [detailData, setDetailData] = useState([]);
  const [loading,setLoading]=useState(false)
  const [error,setError]=useState('')
  // Mock data
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');

      try {
        const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
        const token = userDetails?.token || '';

        // Use the current date as the end date if not set
        const currentEndDate = endDate || new Date().toISOString().split('T')[0];
        // Use 10 days before the end date as the start date if not set
        const currentStartDate = startDate || new Date(new Date(currentEndDate) - 10 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];

        const response = await fetch(AccountStatementUrl, {
          method: 'POST',
          headers: {
            'accept': 'application/json, text/plain, */*',
            'authorization': token,
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            start_date: currentStartDate,
            end_date: currentEndDate,
            statement_type: "0",
            type: "",
            user_name: "",
            user_id: userDetails?.userId || 0,
            page: 1,
            items: 50,
            total_items: 0
          }),
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setFilteredData(data.result || []);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch account statement data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  const handleFilterApply = () => {
    const filtered = mockData.filter((item) => {
      const itemDate = new Date(item.date);
      return (!startDate || itemDate >= new Date(startDate)) &&
             (!endDate || itemDate <= new Date(endDate));
    });
    setFilteredData(filtered);
    handleCloseFilter();
  };

  const handleOpenDetail = (item) => {
    setSelectedItem(item);
    navigate(`/account-statement/${item.orderId}`)
    // setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setSelectedItem(null);
  };

  const renderDetailContent = () => {
    if (!selectedItem) return null;

    const backSubtotal = detailData.filter(item => item.type === 'Back').reduce((sum, item) => sum + item.profitLoss, 0);
    const laySubtotal = detailData.filter(item => item.type === 'Lay').reduce((sum, item) => sum + item.profitLoss, 0);
    const marketSubtotal = backSubtotal + laySubtotal;
    const commission = 0;
    const netMarketTotal = marketSubtotal - commission;
if(loading){
  return <div>Loading...</div>
}
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Account Statement Details
        </Typography>
        <Typography variant="subtitle1" color="primary" gutterBottom>
          {selectedItem.description.eventName} - {selectedItem.description.marketName}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {config.detailFields.map(field => (
                  <TableCell key={field}>{field}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {detailData.map((bet, index) => (
                <TableRow key={index}>
                  <TableCell>{bet.selection}</TableCell>
                  <TableCell>{bet.status}</TableCell>
                  <TableCell>{bet.betId}</TableCell>
                  <TableCell>{formatDate(bet.placed)} {formatTime(bet.placed)}</TableCell>
                  <TableCell>{bet.type}</TableCell>
                  <TableCell>{bet.odds}</TableCell>
                  <TableCell>{bet.stake}</TableCell>
                  <TableCell style={{ color: bet.profitLoss >= 0 ? 'green' : 'red' }}>
                    {bet.profitLoss.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={2}>
          {config.summaryFields.map(field => (
            <Typography key={field.key} variant={field.isTotal ? 'h6' : 'body1'}>
              {field.label}: {eval(field.key).toFixed(2)}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  };
 

  return (
    <AccountStatement>
      <Header>
        <Typography variant="h6">Account Statement</Typography>
      </Header>
      <FilterButton startIcon={<FilterListIcon />} onClick={handleOpenFilter}>
        Filter
      </FilterButton>
      {filteredData.map((item, index) => (
        <StatementCard key={index}>
          <StatementRow>
            <Typography 
              variant="subtitle1" 
              color="primary" 
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenDetail(item)}
            >
              {item.description.eventName}
            </Typography>
            <Typography variant="subtitle1" fontWeight="bold">
              Bal. {item.balance.toFixed(2)}
            </Typography>
          </StatementRow>
          <StatementRow>
            <Typography variant="body2" color="textSecondary">
              Date
            </Typography>
            <Typography variant="body2">
              {formatDate(item.date)}
            </Typography>
          </StatementRow>
          <StatementRow>
            <Typography variant="body2" color="textSecondary">
              Time
            </Typography>
            <Typography variant="body2">
              {formatTime(item.date)}
            </Typography>
          </StatementRow>
          <StatementRow>
            <Typography variant="body2" color="textSecondary">
              Description
            </Typography>
            <Typography variant="body2">
              {item.actionDisplayName}
            </Typography>
          </StatementRow>
          <StatementRow>
            <Typography variant="body2" color="textSecondary">
              P&L
            </Typography>
            <PnlText variant="body2" pnl={item.pnl}>
              {item.pnl >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              {Math.abs(item.pnl).toFixed(2)}
            </PnlText>
          </StatementRow>
          <StatementRow>
            <Typography variant="body2" color="textSecondary">
              Credit Limit
            </Typography>
            <Typography variant="body2">
              {item.creditLimit === null ? '-' : item.creditLimit}
            </Typography>
          </StatementRow>
        </StatementCard>
      ))}
      <Modal
        open={openFilter}
        onClose={handleCloseFilter}
        aria-labelledby="filter-modal-title"
      >
        <ModalContent>
          <Typography id="filter-modal-title" variant="h6" component="h2" gutterBottom>
            Filter by Date Range
          </Typography>
          <TextField
            label="Start Date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleFilterApply} fullWidth sx={{ mt: 2 }}>
            Apply Filter
          </Button>
        </ModalContent>
      </Modal>
      <DetailModal
        open={openDetail}
        onClose={handleCloseDetail}
        aria-labelledby="detail-modal-title"
      >
        <DetailContent>
          {renderDetailContent()}
        </DetailContent>
      </DetailModal>
    </AccountStatement>
  );
};

export default AccountStatementPage;