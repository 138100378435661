import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from '@emotion/styled'
import CricketEvent from '../Cricket/CricketEvent'
import FootballEvent from '../Football/FootballEvent'
import CasinoEvent from '../Casino/CasinoEvent'
import TennisEvent from '../Tennis/TennisEvent'
import { makeStyles, Typography } from '@mui/material'
import {
  useWebSocketContext,
  useWebSocketSelector,
} from '../../hooks/useWebsocket'
import OpenBets from '../OpenBets/OpenBets'

const Header = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #f83c53;
   background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
`

const Title = styled(Typography)`
  padding: 10px;

  color: #fff;
  font-weight: bold;
  margin: 0;
`

const TabContainer = styled.div`
  display: flex;
  background-color: #8a1a24;
`

const TabButton = styled.button`
  flex: 1;
  padding: 10px;
  border: none;
  background-color: ${(props) => (props.active ? '#FFD700' : '#8a1a24')};
  color: ${(props) => (props.active ? 'black' : '#fff')};
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ffd700;
    color: 'black';
  }
`

const TabContent = styled.div`
  padding: 8px 8px 48px 8px;
`

const eventComponents = {
  cricket: CricketEvent,
  football: FootballEvent,
  casino: CasinoEvent,
  tennis: TennisEvent,
}

const CommonEventScreen = () => {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState(0)
  const { subscribeToMarket, unsubscribeFromMarket } = useWebSocketContext()
  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )

  const currentMarketId = location.pathname.split('/')[3];
  const currentEventDetails = marketIds[currentMarketId] || {}

  const EventComponent = eventComponents['cricket'] || (() => <div>Event not found</div>)
  
  useEffect(() => {
    if(currentEventDetails?.match_id) {
      subscribeToMarket(currentMarketId,currentEventDetails?.match_id, true) //subscribing to market
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarketId, currentEventDetails?.match_id])

  useEffect(() => {
    return () => {
      unsubscribeFromMarket([])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarketId])

  return (
    <>
      <Header>
        <Title>{currentEventDetails?.match_name}</Title>
        <TabContainer>
          <TabButton active={activeTab === 0} onClick={() => setActiveTab(0)}>
            Market
          </TabButton>
          <TabButton active={activeTab === 1} onClick={() => setActiveTab(1)}>
            Open Bets (0)
          </TabButton>
        </TabContainer>
      </Header>
      <TabContent>
        {activeTab === 0 && <EventComponent />}
        {activeTab === 1 && <OpenBets />}
      </TabContent>
    </>
  )
}

export default React.memo(CommonEventScreen)

