import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Grid,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SYMBOL } from '../../utils/const';
import SearchIcon from '@mui/icons-material/Search';

const WalletContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: '100vh',
  backgroundColor: '#f5f5f5',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  '& .MuiToggleButton-root': {
    width: '50%',
    color: theme.palette.buttonSecondaryColor,
    '&.Mui-selected': {
      backgroundColor: theme.palette.buttonSecondaryColor,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const NoDepositsIcon = styled(SearchIcon)(({ theme }) => ({
  fontSize: 64,
  color: theme.palette.grey[400],
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.buttonSecondaryColor,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const PaymentOptionButton = styled(Button)(({ theme }) => ({
  justifyContent: 'flex-start',
  borderColor: theme.palette.grey[300],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  transition: 'all 0.3s ease',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    borderColor: theme.palette.buttonSecondaryColor,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
}));

const AmountButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.buttonSecondaryColor,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const WalletPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [view, setView] = useState('deposit');
  const [showForm, setShowForm] = useState(false);
  const [amount, setAmount] = useState('');
  const [paymentOption, setPaymentOption] = useState('PayU');
  const [agreeTerms, setAgreeTerms] = useState(false);

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
      setShowForm(false);
    }
  };

  const handleAddTransaction = () => {
    setShowForm(true);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  const handleAgreeTermsChange = (event) => {
    setAgreeTerms(event.target.checked);
  };

  const handleSubmit = () => {
    console.log(`${view.charAt(0).toUpperCase() + view.slice(1)} submitted:`, { amount, paymentOption, agreeTerms });
  };

  const renderTransactionForm = () => (
    <Box>
      <Typography variant="h6" gutterBottom>
        Payment Options
      </Typography>
      <RadioGroup value={paymentOption} onChange={handlePaymentOptionChange}>
        <Grid container spacing={2}>
          {['PayU', 'UPI Superfast', 'IMPS'].map((option) => (
            <Grid item xs={12} sm={4} key={option}>
              <PaymentOptionButton
                fullWidth
                variant="outlined"
                component="label"
                selected={paymentOption === option}
              >
                <Radio value={option} />
                {option}
              </PaymentOptionButton>
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
      <Typography variant="caption" color="textSecondary" sx={{ mt: 2, display: 'block' }}>
        *Always start instant payments by submitting amount below.
      </Typography>
      <TextField
        fullWidth
        label="Amount"
        variant="outlined"
        type="number"
        value={amount}
        onChange={handleAmountChange}
        sx={{ mt: 2 }}
        InputProps={{
          startAdornment: <Typography>{SYMBOL}</Typography>,
        }}
      />
      <Grid container spacing={1} sx={{ mt: 1 }}>
        {[100, 500, 1000, 5000, 10000, 50000].map((value) => (
          <Grid item key={value}>
            <AmountButton variant="contained" onClick={() => setAmount(value.toString())}>
              +{value}
            </AmountButton>
          </Grid>
        ))}
      </Grid>
      <FormControlLabel
        control={<Checkbox checked={agreeTerms} onChange={handleAgreeTermsChange} />}
        label={
          <Typography variant="body2">
            I have read and agree with the{' '}
            <a href="#terms">terms of payment and withdrawal policy</a>.
          </Typography>
        }
        sx={{ mt: 2 }}
      />
      <StyledButton
        fullWidth
        variant="contained"
        onClick={handleSubmit}
        disabled={!agreeTerms || !amount}
        sx={{ mt: 2 }}
      >
        Submit
      </StyledButton>
    </Box>
  );

  return (
    <WalletContainer>
      <StyledPaper>
        <StyledToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          aria-label="deposit or withdrawal"
        >
          <ToggleButton value="deposit" aria-label="deposit">
            Deposit
          </ToggleButton>
          <ToggleButton value="withdrawal" aria-label="withdrawal">
            Withdrawal
          </ToggleButton>
        </StyledToggleButtonGroup>

        {!showForm ? (
          <Box display="flex" flexDirection="column" alignItems="center" py={4}>
            <NoDepositsIcon />
            <Typography variant="h6" color="textSecondary" mt={2}>
              No {view === 'deposit' ? 'Deposits' : 'Withdrawals'} found
            </Typography>
            <StyledButton variant="contained" onClick={handleAddTransaction} sx={{ mt: 2 }}>
              {view === 'deposit' ? '+ Add Deposit' : '+ Add Withdrawal'}
            </StyledButton>
          </Box>
        ) : (
          renderTransactionForm()
        )}
      </StyledPaper>
    </WalletContainer>
  );
};

export default WalletPage;