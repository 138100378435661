import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { Skeleton, Grid, Typography, styled, useTheme, Divider } from '@mui/material'
import './../0_Common/0_Common.css'
import ListCard from '../0_Common/ListCard'
import SpadeIcon from '../../icons/spadeicon'
import PopularIcon from '../../icons/popularIcon'
import UpcomingIcon from '../../icons/upcomingicon'
import CasinoIcon from '../../icons/casinoicon.jsx'
import {
  useWebSocketContext,
  useWebSocketSelector,
} from '../../hooks/useWebsocket'


const buttonSecondaryColor = '#F83C53'


const generateRandomSportsData = (existingData) => {
  const sports = ['Football', 'Tennis', 'Casino', 'Basketball'];
  const venues = ['London Stadium', 'Roland Garros', 'Monte Carlo Casino', 'Madison Square Garden'];

  function getRandomItem(array) {
    return array[Math.floor(Math.random() * array.length)];
  }

  function generateRandomId() {
    return Math.floor(Math.random() * 1000000).toString();
  }

  function generateRandomDate() {
    const start = new Date();
    const end = new Date(start.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days from now
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime())).toISOString();
  }

  return existingData.map(item => {
    const sport = getRandomItem(sports);
    return {
      ...item,
      id: generateRandomId(),
      sport_id: generateRandomId(),
      sport_name: sport,
      series_id: generateRandomId(),
      series_name: `${sport} League ${new Date().getFullYear()}`,
      match_id: generateRandomId(),
      match_name: `Team A vs Team B`,
      match_date: generateRandomDate(),
      venue: getRandomItem(venues),
      market_id: `1.${generateRandomId()}`,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
  });
}


const config = {
  sectionIcons: {
    indianCardGames: <SpadeIcon color={buttonSecondaryColor} />,
    popularGames: <PopularIcon color={buttonSecondaryColor} />,
    casino: <CasinoIcon color={buttonSecondaryColor} />,
    upcomingEvents: <UpcomingIcon color={'white'} />,
  },
  sports: {
    Cricket: { color: '#8b4513' },
    Football: { color: '#8b0000' },
  },
  tags: {
    NEW: { backgroundColor: 'red' },
    LIVE: { backgroundColor: 'green' },
    POPULAR: { backgroundColor: 'blue' },
  },
}

const StyledCard = styled(Grid)(({ theme }) => ({
  backgroundColor: '#fff',
  marginBottom: 20,
  padding: theme.spacing(1),
  borderRadius: '10px',
  boxShadow: 'none',
}))

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '14px',
  color: theme.palette.primary.main,
  display: 'flex',
  gap: '10px',
  padding: '10px 8px',
  marginBottom: '10px',
  alignItems: 'center',
}))

const GameCard = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(1),
  position: 'relative',
  boxShadow: theme.shadows[0],
}))

const GameImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  borderRadius: theme.shape.borderRadius,
}))

const GameTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  left: theme.spacing(1),
  color: theme.palette.common.white,
  fontSize: '14px',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
}))

const GameTag = styled('div')(({ theme, tagType }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  backgroundColor:
    config.tags[tagType]?.backgroundColor || theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: '2px 5px',
  fontSize: '10px',
  borderRadius: theme.shape.borderRadius,
}))

const HomeGrid = () => {
  const theme = useTheme()
  const { subscribeToMarket, unsubscribeFromMarket, marketsIdsArray } = useWebSocketContext();
  const [mergedMarketIds, setMergedMarketIds] = useState({});
  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )

  const marketIdsList = useMemo(() =>
    Object.values(marketIds)?.map((i) => String(i.market_id)),
    [marketIds]
  );

  // Effect for subscribing to markets
  useEffect(() => {
    subscribeToMarket(marketIdsList);
    return () => unsubscribeFromMarket([]);
  }, [marketIdsList, subscribeToMarket, unsubscribeFromMarket]);

  // Effect for merging marketIds and marketsIdsArray
  useEffect(() => {
    const updatedMarketIds = { ...marketIds };
    marketsIdsArray.forEach(i => {
      if (updatedMarketIds[i.market_id]) {
        updatedMarketIds[i.market_id] = { ...updatedMarketIds[i.market_id], ...i };
      }
    });
    setMergedMarketIds(updatedMarketIds);
  }, [marketIds, marketsIdsArray]);


  let data = useMemo(() => {
    const uniqueData = {};
    Object.values(mergedMarketIds).forEach(item => {
      if (!uniqueData[item.id]) {
        uniqueData[item.id] = item;
      }
    });
    return Object.values(uniqueData);
  }, [mergedMarketIds]);

  useEffect(() => {
    return () => {
      unsubscribeFromMarket([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
   * 
   * Adding Dummy JSON for other sports
   */

  // let dummyArr = generateRandomSportsData(data)
  // data = [...data, ...dummyArr]


  const CardLoading = () => (
    <>
      {[...Array(9)].map((_, index) => (
        <>
          <Skeleton key={index} animation="wave" height={'100px'} />
          <br></br>
        </>

      ))}
    </>
  )

  const gameCategories = {
    indianCardGames: [
      {
        name: 'Teen Patti',
        image:
          'https://mac.imgix.net/gemexchimages/teenpattit20.webp?auto=format',
        tag: 'LIVE',
      },
      {
        name: 'Teen Patti Test',
        image:
          'https://mac.imgix.net/gemexchimages/teenpatti_test.png?auto=forma',
      },
      {
        name: '32 Cards Casino',
        image:
          'https://mac.imgix.net/gemexchimages/32casinovirtual.webp?auto=format',
      },
      {
        name: 'Andar Bahar Anthony',
        image:
          'https://mac.imgix.net/gemexchimages/andarbahar1.webp?auto=format',
      },
      {
        name: 'Teen Patti T20',
        image:
          'https://mac.imgix.net/gemexchimages/teenpattit20virtual.webp?auto=format',
      },
      {
        name: '20-20 Poker',
        image: 'https://mac.imgix.net/gemexchimages/2020poker.webp?auto=format',
      },
      {
        name: 'Hi-Low',
        image:
          'https://mac.imgix.net/gemexchimages/hilowvirtual.webp?auto=format',
      },
      {
        name: 'Casino War',
        image: 'https://mac.imgix.net/gemexchimages/casinowar.png?auto=format',
      },
      {
        name: 'Muflis Teen Patti',
        image:
          'https://mac.imgix.net/gemexchimages/teenpattit20virtual.webp?auto=format',
      },
      {
        name: 'Andar Bahar',
        image:
          'https://mac.imgix.net/gemexchimages/teenpattit20virtual.webp?auto=format',
      },
      {
        name: 'Roulette',
        image:
          'https://mac.imgix.net/gemexchimages/andarbahar1.webp?auto=format',
      },
      {
        name: 'Queen Race',
        image: 'https://mac.imgix.net/gemexchimages/Queen_race.png?auto=format',
      },
    ],
    popularGames: [
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
        tag: 'LIVE',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
    ],
    casino: [
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
        tag: 'LIVE',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
      {
        name: '',
        image:
          'https://th.bing.com/th/id/OIP.G-FDa3QJDc3Bqyo6yyWe-QHaEK?rs=1&pid=ImgDetMain',
      },
    ],
  }

  return (
    <div style={{ padding: '4px' }}>
      <Suspense fallback={<CardLoading />}>
        {/**
 * Render Inplay
 */}
        <ListCard
          data={data || []}
          active={true}
          showNonActive="active"
          limitProp={4}
        />
      </Suspense>

      {/**
 * Render Upcoming
 */}
      <Suspense fallback={<CardLoading />}>
        <ListCard data={data} limitProp={2} active={false} showNonActive='upcoming' />
      </Suspense>


      {Object.entries(gameCategories).map(([category, games]) => (
        <React.Fragment key={category}>

          <StyledCard>
            <SectionTitle>
              {config.sectionIcons[category]}
              {category.charAt(0).toUpperCase() +
                category
                  .slice(1)
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}
            </SectionTitle>
            <Grid container spacing={1}>
              {games.map((game, index) => (
                <GameCard item xs={6} sm={4} md={3} key={index}>
                  <GameImage src={game.image} alt={game.name} />
                  <GameTitle>{game.name}</GameTitle>
                  {game.tag && <GameTag tagType={game.tag}>{game.tag}</GameTag>}
                </GameCard>
              ))}
            </Grid>
          </StyledCard>
        </React.Fragment>
      ))}

    </div>
  )
}

export default HomeGrid
