import HomeIcon from './../icons/HomeIcon.tsx'
import FootBall from './../icons/FootBall.tsx'
import CricketBall from './../icons/CricketBall.tsx'
import ProfileIcon from '../icons/profileicon.js'
import WithdrawIcon from '../icons/withdrawicon.js'
import AccountIcon from '../icons/accounticon.js'
import OpenBetIcon from '../icons/openbeticon.js'
import ResetIcon from '../icons/reseticon.js'
import { Logout } from '@mui/icons-material'
import ProfitIcon from '../icons/profiticon.js'
import RulesIcon from '../icons/ruleicon.js'
import SettingsIcon from '../icons/settingsicon.js'
import ExclusionIcon from '../icons/exclusionpolicy.js'
import OkIcon from '../icons/okicon.js'
import PrivacyIcon from '../icons/privacyicon.js'
import BetHistoryIcon from '../icons/bethistoryicon.jsx'

export const SYMBOL = '₹';

export const TAB = {
    ALL: "/",
    CRICKET: "/sports/CRICKET",
    FOOTBALL: "/sports/FOOTBALL",
    CASINO: "/sports/CASINO",
    TENNIS: "/sports/TENNIS"

}

export const navMenuList = [
    {   id:1,
        title:'Home',
        link:'/',
        icon:HomeIcon,
        
    },
    {   id:2,
        title:'Cricket',
        link:'/pages/cricket',
        icon:CricketBall,
        subCateogary:[
            {
                id:'c11',
                title:"Sub Cat 1",
                link:'/'
            },
            {
                id:'c12',
                title:"Sub Cat 3",
                link:'/'
            },
            {
                id:'c13',
                title:"Sub Cat 3",
                link:'/'
            },
            {
                id:'c14',
                title:"Sub Cat 4",
                link:'/'
            },
        ]
    },
    {   id:3,
        title:'Football',
        link:'/pages/football',
        icon:FootBall,
        subCateogary:[
            {
                id:'f11',
                title:"Sub Cat 1",
                link:'/'
            },
            {
                id:'f12',
                title:"Sub Cat 2",
                link:'/'
            },
            {
                id:'f13',
            title:"Sub Cat 3",
                link:'/'
            },
            {
                id:'f14',
                title:"Sub Cat 4",
                link:'/'
            },
        ]
    },
    {   id:2,
        title:'Cricket',
        link:'/pages/cricket',
        icon:CricketBall
    },
]





export const demoUserData ={
    "id": 8767,
    "slot_game_id": null,
    "user_name": "user",
    "name": "Demo User",
    "user_type_id": 10,
    "user_cat_name": "Blue",
    "mobile": null,
    "referred_code": null,
    "parent_id": 2,
    "is_change_password": "1",
    "token": "8767.Y2x6NXFzcjMwMDAwMDBxbzQ4c2psN3hwZA.FtfCoIeSJE3YQQhiQPndu4ya2gcz6DPiWHDZBB18uFM9YZVU1J014M0expFX",
    "withrawal": 0,
    "deposit": 0,
    "balance": 99691,
    "total_balance": 100000,
    "total_bonus": 0,
    "lock_bonus": 0,
    "profit_loss": -55,
    "liability": -254,
    "referred_amount": 0,
    "bonus_amount": 0,
    "parent_name": null,
    "reward_amount": 0,
    "stack": null,
    "oc_stack": null,
    "last_login": "2024-07-28T07:19:02.000Z",
    "withdrawal_lock_amt": 0,
    "transfer_lock_amt": 0,
    "drop_lock_amt": 0
}

const buttonSecondaryColor =  '#F83C53';

export const profileMenuOptions =[
    {
        name:'Profile',
        link:'/profile',
        icon: <ProfileIcon color={buttonSecondaryColor} />
    },
    {
        name:'Open Bets',
        link:'/open-bets',
        icon:<OpenBetIcon color={buttonSecondaryColor} />
    },
    {
        name:'Bet History',
        link:'/bet-history',
        icon:<BetHistoryIcon color={buttonSecondaryColor}/>
    },
    {
        name:'Profit & Loss',
        link:'/profit-loss',
        icon:<ProfitIcon color={buttonSecondaryColor}/>
    },
    // {
    //     name:'Wallet',
    //     link:'/wallet',
    //     icon:<WalletIcon />
    // },
    {
        name:'Deposit / Withdraw',
        link:'/deposit-withdraw',
        icon:<WithdrawIcon color={buttonSecondaryColor}/>
    },
    {
        name:'Account Statement',
        link:'/account-statement',
        icon:<AccountIcon  color={buttonSecondaryColor}/>
    },
    // {
    //     name:'Rules & Regulation',
    //     link:'/rules',
    //     icon:<RulesIcon color={buttonSecondaryColor}/>
    // },
    {
        name:'Settings',
        link:'/settings',
        icon:<SettingsIcon color={buttonSecondaryColor} />
    },
    // {
    //     name:'Exclusion Policy',
    //     link:'/exclusion',
    //     icon:<ExclusionIcon color={buttonSecondaryColor}/>
    // },
    // {
    //     name:'Responsible Gaming',
    //     link:'/responsible',
    //     icon:<OkIcon color={buttonSecondaryColor}/>
    // },
    {
        name:'Privacy Policy',
        link:'/privacy-policy',
        icon:<PrivacyIcon color={buttonSecondaryColor}/>
    },
    {
        name:'Reset Password',
        link:'/reset-password',
        icon:<ResetIcon color={buttonSecondaryColor}/>
    },
    {
        name:'Logout',
        link:'/logout',
        icon:<Logout color={buttonSecondaryColor}/>
    },

]

export const iconsConst={
    'cricket':<CricketBall color={buttonSecondaryColor}/>,
    'cricketWhite':<CricketBall color={'#e4e9f0'}/>,
}

export const WHATSAPP_NUMER = "1234567890"