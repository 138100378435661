import React, { useState, useLayoutEffect,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTheme, styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Typography,
  Button,
  Modal,
  Box,
  Container,
  ListItemIcon,
  ListItemText,
  Divider,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  IconButton,
} from '@mui/material'
import { AccountBalance, Menu as MenuIcon } from '@mui/icons-material'
import Login from './Login'
import Signup from './Signup'
import DateComponent from './DateComponent'
import { profileMenuOptions } from '../../utils/const'
import SideBarLeft from '../SideBarLeft/SideBarLeft'
import Logo from '../0_Common/Logo'

// Styled components
const ModalStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'none',
  border: 'none',
  boxShadow: 0,
  p: 4,
}

const ThemedButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${theme.palette.buttonSecondaryColor} !important`,
  color: 'white',
  margin: theme.spacing(1),
}))

const StyledAppBar = styled(AppBar)(({ theme }) => ({


  backgroundColor: theme.palette.primary.main,
  zIndex: '100',
  [theme.breakpoints.up('sm')]: {
    height:'80px', // Increased height for desktop view
  },
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    minHeight: 80, // Match the AppBar height
  },
}))

const RootContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))



const UserName = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  color: 'white',
}))

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.bgColor,
    color: '#fff',
  },
}))

// Helper components
const MenuContent = ({ user, handleMenuClick, theme }) => (
  <>
    <MenuItem>
      <Typography variant="h6" style={{ color: 'white' }}>
        {user?.name}
      </Typography>
    </MenuItem>
    <MenuItem>
      <ListItemIcon>
        <AccountBalance color={'secondary'} />
      </ListItemIcon>
      <ListItemText primary="Balance Information" sx={{ color: 'white' }} />
    </MenuItem>
    <MenuItem>
      <ListItemText
        primary={`Available Balance: ${user?.balance}`}
        sx={{ color: 'white' }}
      />
    </MenuItem>
    <MenuItem>
      <ListItemText
        primary={`Net Exposure: ${user?.liability}`}
        sx={{ color: 'white' }}
      />
    </MenuItem>
    <Divider sx={{ backgroundColor: 'white', my: 1 }} />
    {profileMenuOptions
      .filter((el) => el.name.toLowerCase() !== 'logout')
      .map((el) => (
        <MenuItem key={el.name} onClick={() => handleMenuClick(el.link)}>
          <ListItemIcon sx={{ color: '#fff' }}>{el.icon}</ListItemIcon>
          <ListItemText primary={el.name} />
        </MenuItem>
      ))}
    {profileMenuOptions
      .filter((el) => el.name.toLowerCase() === 'logout')
      .map((el) => (
        <MenuItem
          key={el.name}
          onClick={() => handleMenuClick(el.link)}
          sx={{ mt: 'auto', background: theme.palette.buttonSecondaryColor }}
        >
          <ListItemIcon sx={{ color: '#fff' }}>{el.icon}</ListItemIcon>
          <ListItemText primary={el.name} />
        </MenuItem>
      ))}
  </>
)

const TopHeader = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [modal, setModal] = useState({ active: false, type: '' })
  const [user, setUser] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
console.log('top header rendering')
const userDetails = localStorage.getItem('userDetails')
  useLayoutEffect(() => {
    if (!userDetails) {
      setUser(null)
    }
    setUser(JSON.parse(userDetails))
  }, [userDetails])

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)
  const handleSidebarToggle = () => setSidebarOpen(!sidebarOpen)

  const handleMenuClick = (option) => {
    if (option === '/logout') {
      localStorage.removeItem('userDetails')
      navigate('/')
      window.location.reload()
    } else {
      navigate(option)
    }
    handleMenuClose()
  }

  const handleModalOpen = (type) => setModal({ active: true, type })
  const handleModalClose = () => setModal({ active: false, type: '' })

  const renderUserContent = () => (
    <>
      <UserName variant="subtitle1">{user?.name}</UserName>
      <Avatar
        onClick={handleMenuOpen}
        alt={user?.name}
        src={user?.avatarSrc}
        sx={{ cursor: 'pointer' }}
      />
    </>
  )

  const renderAnonymousContent = () => (
    <>
      <ThemedButton
        variant="contained"
        id="loginButton"
        onClick={() => handleModalOpen('login')}
      >
        <Typography>Login</Typography>
      </ThemedButton>
      {/* <ThemedButton
        variant="contained"
        id="signupButton"
        onClick={() => handleModalOpen('signup')}
      >
        <Typography>Signup</Typography> 
       </ThemedButton> */}
    </>
  )

  return (
    <>
      <StyledAppBar position="fixed">
        <RootContainer maxWidth={false} sx={{paddingTop:'0px',paddingBottom:'0px'}}>
          <StyledToolbar sx={{ justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isMobile && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleSidebarToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Link to='/' style={{textDecoration:'none'}}>
              <Logo size='1.8rem'>

              </Logo>
              </Link>
             
              {!isMobile && <DateComponent />}
            </Box>
            {/* {!isMobile && <Search />} */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {user ? renderUserContent() : renderAnonymousContent()}
            </Box>
          </StyledToolbar>
        </RootContainer>
      </StyledAppBar>
      <Toolbar sx={{ minHeight: { sm: 80 } }} />{' '}
      {/* Adjust the spacer height */}
      <Modal open={modal.active} onClose={handleModalClose}>
        <Box sx={ModalStyle}>
          {modal.type === 'login' ? (
            <Login onClose={handleModalClose} />
          ) : (
            <Signup onClose={handleModalClose} />
          )}
        </Box>
      </Modal>
      {user && (
        <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuContent
            user={user}
            handleMenuClick={handleMenuClick}
            theme={theme}
          />
        </StyledMenu>
      )}
      {isMobile && (
        <SideBarLeft
          onBackdropClick={() => setSidebarOpen(false)}
          toggled={sidebarOpen}
          breakPoint="always"
        />
      )}
    </>
  )
}

export default React.memo(TopHeader)
