import React, { useEffect, useState } from 'react';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { Typography, Button, Modal, Box, TextField, IconButton, InputAdornment, Menu, MenuItem } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import GoogleIcon from '@mui/icons-material/Google';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useApiFetch from '../../hooks/useApiFetch';
import { loginUrl } from '../../constants';
import { demoUserData } from '../../utils/const';
import Logo from './Logo';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F83C53',
    },
    background: {
      default: '#242323',
    },
  },
});

const LoginContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  maxWidth: 400,
  margin: 'auto',
  marginTop: '10%',
}));

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const SocialButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

function Login({ onClose }) {
  const [formData, setFormData] = useState({
    identifier: '',
    password: '',
  });
  const [loginType, setLoginType] = useState('User ID');
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, fetchData } = useApiFetch(loginUrl, {
    method: 'POST',
    body: JSON.stringify({
      "user_name": formData.identifier,
      "password": formData.password,
      "mobile": "",
      "otp": "",
      "login_type": loginType === 'User ID' ? 'user_name' : 'mobile'
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    if (!!data) {
      localStorage.setItem('userDetails', JSON.stringify(data.data));
      window.location.reload();
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await fetchData();
  };

  // const handleDemoIdClick = () => {
  //   localStorage.setItem('userDetails', JSON.stringify(demoUserData));
  //   window.location.reload();
  // };

  const handleLoginTypeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLoginTypeClose = (type) => {
    if (type) {
      setLoginType(type);
    }
    setAnchorEl(null);
  };

  return (
    <ThemeProvider theme={theme}>
      <LoginContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Logo />
          <Typography variant="h5" color="common.white">Signup</Typography>
          <IconButton onClick={onClose} sx={{ color: 'common.white' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            fullWidth
            variant="filled"
            name="identifier"
            placeholder={loginType === 'User ID' ? 'ID' : 'Mobile Number'}
            value={formData.identifier}
            onChange={handleChange}
            InputProps={{
              style: { backgroundColor: 'white' },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleLoginTypeClick}>
                    <ArrowDropDownIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleLoginTypeClose()}
          >
            <MenuItem onClick={() => handleLoginTypeClose('User ID')}>User ID</MenuItem>
            <MenuItem onClick={() => handleLoginTypeClose('Mobile Number')}>Mobile Number</MenuItem>
          </Menu>
          <TextField
            fullWidth
            variant="filled"
            type="password"
            name="password"
            placeholder="Password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              style: { backgroundColor: 'white' }
            }}
          />
          {/* <Typography variant="body2" color="common.white" align="right" sx={{cursor: 'pointer', textDecoration: 'underline'}}>
            Forgot password?
          </Typography> */}
          <StyledButton fullWidth type="submit" variant="contained">
            Signup
          </StyledButton>
          {/* <StyledButton fullWidth variant="contained" onClick={handleDemoIdClick}>
            Login with Demo ID
          </StyledButton> */}
          {/* <Typography variant="body2" color="common.white" align="center">
            Or Log in with
          </Typography> */}
          <Box display="flex" justifyContent="space-between">
            <SocialButton startIcon={<GoogleIcon />} variant="contained">
              Get Your ID
            </SocialButton>
            <SocialButton startIcon={<WhatsAppIcon />} variant="contained">
              WhatsApp
            </SocialButton>
          </Box>
          <Typography variant="body2" color="common.white" align="center" sx={{cursor: 'pointer', textDecoration: 'underline'}}>
           Terms and Conditions 
          </Typography>
        </StyledForm>
      </LoginContainer>
    </ThemeProvider>
  );
}

export default Login;