import React, { useCallback, useMemo } from "react";
import { Stack, Tab, Tabs, Typography, Fab } from "@mui/material";
import "./CategoryNavigation.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import { TAB, WHATSAPP_NUMER } from "../../utils/const";
import CricketBallIcon from "../../icons/cricketballicon";
import { FootballIcon } from "../../icons/footballicon";
import CasinoIcon from "../../icons/casinoicon.jsx";
import { TennisIcon } from "../../icons/tennisicon";
import { useLocation, useNavigate } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ChatIcon from '@mui/icons-material/Chat';
import { Home } from "@mui/icons-material";

const tabConfig = [
    { value: TAB.ALL, label: "Home", Icon: Home },
    { value: TAB.CRICKET, label: "Cricket", Icon: CricketBallIcon },
    { value: TAB.FOOTBALL, label: "Football", Icon: FootballIcon },
    { value: TAB.CASINO, label: "Casino", Icon: CasinoIcon },
    { value: TAB.TENNIS, label: "Tennis", Icon: TennisIcon },
];


const CategoryNavigation = React.memo(({ currentTab, updateTab }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
    const navigate = useNavigate();
    const location = useLocation();
    if(currentTab === '/'){
        currentTab = undefined
    }

    const handleWhatsAppClick = () => {
        const whatsappUrl = `https://wa.me/${WHATSAPP_NUMER}`;
        window.open(whatsappUrl, '_blank');
    };

    const tabStyle = useMemo(() => ({
        color: "white",
        textTransform: "none",
        alignItems: isDesktop ? 'start' : 'center',
        '&:first-of-type': {
            borderTopLeftRadius: '2em',
            borderBottomLeftRadius: '2em',
        },
        '&:last-of-type': {
            borderTopRightRadius: '2em',
            borderBottomRightRadius: '2em',
        },
    }), [isDesktop]);

    const selectedTabStyle = useMemo(() => ({
        ...tabStyle,
        background: theme.palette.buttonSecondaryColor,
    }), [tabStyle, theme.palette.buttonSecondaryColor]);

    const handleTabChange = useCallback((event, newValue) => {
        navigate(newValue);
        updateTab(newValue.split('/')[2]);

    }, [navigate, updateTab]);

    const renderTab = ({ value, label, Icon }, index) => {

        const isSelected = value === TAB.ALL
            ? location.pathname === value
            : location.pathname.toLowerCase().includes(value.toLowerCase());

        return (
            <Tab
                key={value}
                value={value}
                sx={{
                    ...tabStyle,
                    ...(isSelected ? selectedTabStyle : {}),
                }}
                label={
                    <Stack
                        direction={isDesktop ? "row" : "column"}
                        alignItems="center"
                        spacing={0}
                    >
                        <Icon />
                        <Typography>{label}</Typography>
                    </Stack>
                }
            />
        )
    };

    return (
        <div className="footer">
            <Fab
                color="primary"
                aria-label="whatsapp"
                style={{
                    position: 'fixed',
                    bottom: isDesktop ? 20 : 70,
                    left: 20,
                    zIndex: 1000,
                    marginBottom: 10,
                    background: '#48c456'

                }}
                onClick={handleWhatsAppClick}
            >
                <WhatsAppIcon />
            </Fab>

            {/* Chat Icon */}
            <Fab
                color="secondary"
                aria-label="chat"
                style={{
                    position: 'fixed',
                    bottom: isDesktop ? 20 : 70,
                    right: 20,
                    zIndex: 1000,
                    marginBottom: 10
                }}
                onClick={handleWhatsAppClick}
            >
                <ChatIcon />
            </Fab>
            <Tabs
                orientation={isDesktop ? "vertical" : "horizontal"}
                centered
                value={currentTab}
                onChange={handleTabChange}
            >
                {useMemo(() => tabConfig.map(renderTab), [location.pathname, tabConfig, renderTab])}
            </Tabs>
        </div>
    );
});

export default CategoryNavigation;