import * as React from 'react'
import {
  Button,
  Divider,
  Grid,
  Typography,
  Collapse,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import BetModifyComponent from './BetModifyComponent'
import { useWebSocketContext, useWebSocketSelector } from '../../hooks/useWebsocket'
import { useLocation } from 'react-router-dom'

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  textAlign: 'center',
  color: theme.palette.text.primary,
}))

const StyledButton = styled(Button)(({ theme, variant }) => ({
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '2px 2px 2px !important',
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0,
  width: '95%',
  background: variant === 'back'
    ? theme.palette.backButtonColor
    : theme.palette.layButtonColor,
  '&:hover': {
    background: variant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:active': {
    background: variant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:focus': {
    background: variant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
}))

const TeamTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(1.25),
  fontWeight: 700,
}))

const CardRoot = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.25),
}))

const PLTypography = styled(Typography)(({ theme, isPositive }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  color: isPositive ? 'green' : 'red',
  backgroundColor: isPositive ? '#e6ffe6' : '#ffe6e6',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  marginLeft: 'auto',
}))

const TitleContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  marginBottom: theme.spacing(1),
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 700,
  letterSpacing: '0.5px',
}))

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  marginTop: theme.spacing(0.5),
}))

const MatchOddsCard = () => {
  const location = useLocation()
  const [openMatchOddsBetModify, setOpenMatchOddsBetModify] = React.useState(null)
  const [openBookmakerBetModify, setOpenBookmakerBetModify] = React.useState(null)
  const { status, marketIds, eventOdds } = useWebSocketSelector(
    (state) => state.websocket,
  )
  
  const {updatedMarketDetails,marketsIdsArray, bookmakerOdds} = useWebSocketContext()
  const currentGameIdFromLocation = location.pathname.split('/')[3]
  const matchOddsData = marketsIdsArray.find(i => i.marketId === currentGameIdFromLocation)
  const currentBookmakerOdds = Object.values(bookmakerOdds)[0]

  if (!matchOddsData || status !== 'CONNECTED') return <div></div>
  
  const toggleMatchOddsBetModify = (index, side, odds) => {
    setOpenMatchOddsBetModify(prev => {
      if (prev?.index === index && prev.side === side) {
        return null;
      } else {
        return { isOpen: true, side, odds, market: {...matchOddsData, ...marketIds[currentGameIdFromLocation]}, index };
      }
    });
  }

  const toggleBookmakerBetModify = (index, side, odds) => {
    setOpenBookmakerBetModify(prev => {
      if (prev?.index === index && prev.side === side) {
        return null;
      } else {
        return { isOpen: true, side, odds, market: {...currentBookmakerOdds}, index };
      }
    });
  }

  const closeMatchOddsBetModify = () => {
    setOpenMatchOddsBetModify(null)
  }

  const closeBookmakerBetModify = () => {
    setOpenBookmakerBetModify(null)
  }

  const updatedRunnerJSON = updatedMarketDetails?.markets?.find(i => i?.market_id === matchOddsData?.market_id)?.runner_json;
  
  const renderOddsCard = (data, title, minStack, maxStack, updatedRunnerJSON, betModifyState, toggleBetModify, closeBetModify) => (
    <CardRoot>
      <TitleContainer>
        <TitleTypography variant="h6">{title}</TitleTypography>
        <SubtitleTypography variant="subtitle2">
          Min: {minStack} &nbsp; Max: {maxStack}
        </SubtitleTypography>
      </TitleContainer>

      <Grid container spacing={1} sx={{ padding: '0 8px 8px' }}>
        <Grid item xs={7}>
          {/* Empty grid item for alignment */}
        </Grid>
        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <StyledTypography>Back</StyledTypography>
        </Grid>
        <Grid item xs={2.5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <StyledTypography>Lay</StyledTypography>
        </Grid>
      </Grid>

      <Divider />

      <Grid container>
        {data && data['runners']?.map((i, index) => (
          <React.Fragment key={i.id}>
            <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
              <TeamTypography>{i.name}</TeamTypography>
              {updatedRunnerJSON && updatedRunnerJSON[index] && updatedRunnerJSON[index].pl !== undefined && (
                <PLTypography isPositive={updatedRunnerJSON[index].pl >= 0}>
                  {updatedRunnerJSON[index].pl >= 0 ? '+' : '-'}
                  {Math.abs(updatedRunnerJSON[index].pl)}
                </PLTypography>
              )}
            </Grid>
            <Grid item xs={2.5} sx={{ marginBottom: '3.1px' }}>
              <StyledButton
                variant="back"
                onClick={() => toggleBetModify(index, 'back', i.ex['availableToBack'][0].price)}
                sx={{ marginBottom: '3.1px', borderRadius: '8px 0px 0px 8px ', padding: '8px !important' }}
              >
                <Typography fontSize={'12px'} fontWeight={600}>
                  {i.ex['availableToBack'][0].price}
                </Typography>
                <Typography fontSize={'9px'}>
                  {i.ex['availableToBack'][0].size}
                </Typography>
              </StyledButton>
            </Grid>
            <Grid item xs={2.5}>
              <StyledButton
                variant="lay"
                onClick={() => toggleBetModify(index, 'lay', i.ex['availableToLay'][0].price)}
                sx={{ marginBottom: '3.1px', borderRadius: '0px 8px 8px 0px ', padding: '8px !important' }}
              >
                <Typography fontSize={'12px'} fontWeight={600}>
                  {i.ex['availableToLay'][0].price}
                </Typography>
                <Typography fontSize={'9px'}>
                  {i.ex['availableToLay'][0].size}
                </Typography>
              </StyledButton>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={betModifyState?.index === index}>
                {betModifyState?.index === index && (
                  <BetModifyComponent
                    marketDetails={betModifyState}
                    onCancel={closeBetModify}
                  />
                )}
              </Collapse>
              <Divider />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </CardRoot>
  )

  return (
    <>
      {renderOddsCard(
        matchOddsData,
        "Match Odds",
        marketIds?.[currentGameIdFromLocation]?.min_stack,
        marketIds?.[currentGameIdFromLocation]?.max_stack,
        updatedRunnerJSON,
        openMatchOddsBetModify,
        toggleMatchOddsBetModify,
        closeMatchOddsBetModify
      )}
      {currentBookmakerOdds && renderOddsCard(
        currentBookmakerOdds,
        "Bookmaker",
        currentBookmakerOdds.min_stack,
        currentBookmakerOdds.max_stack,
        updatedRunnerJSON,
        openBookmakerBetModify,
        toggleBookmakerBetModify,
        closeBookmakerBetModify
      )}
    </>
  )
}

export default MatchOddsCard