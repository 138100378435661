import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Chip,
  TextField,
  Select,
  MenuItem,
  Button,
  Alert,
  Pagination,
  Card,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

// Assuming you have a theme file, adjust the import path as needed
import theme from '../../utils/customTheme';
import { BetHistoryUrl } from '../../constants';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  minHeight: '100vh',
  padding: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  fontWeight: 'bold',
  color: theme.palette.primary.main,
}));

const FilterBox = styled(Box)(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const OpenBetsPage = () => {
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [winStatus, setWinStatus] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // New filter states
  const [deleteStatus, setDeleteStatus] = useState('');
  const [betType, setBetType] = useState('');
  const [type, setType] = useState('');
  const [sport, setSport] = useState('');

  useEffect(() => {
    fetchBets();
  }, [page, startDate, endDate, winStatus, deleteStatus, betType, type, sport]);

  const fetchBets = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(BetHistoryUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Authorization': JSON.parse(localStorage.getItem('userDetails') || '{}').token || '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          result_id: 'current',
          user_id: JSON.parse(localStorage.getItem('userDetails') || '{}').id || 0,
          user_name: '',
          sport_id: sport, // 1 - all, 2 - football, 3 - Tennis, 4 - cricket, 5 - Live Game
          is_fancy: betType === 'session' ? '2' : betType === 'match' ? '1' : '', // 1 - match, 2 - session
          delete_status: deleteStatus === 'delete' ? '1' : deleteStatus === 'undelete' ? '0' : '', // 0 - not deleted, 1 - deleted
          is_back: type === 'lay' ? 'lay' : type === 'back' ? 'back' : '', // lay - Lay, back - Back
          win_status: winStatus,
          start_date: startDate,
          end_date: endDate,
          page: page,
          total_items: 0,
          items: 50
        })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setBets(data.data.data || []);
      setTotalPages(data.data.pageCount || 1);
      console.log('Page Count:', data.data.pageCount);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const renderBetCard = (bet) => (
    <StyledCard key={bet.id}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{bet.match_name}</Typography>
            <Typography color="textSecondary">{bet.series_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Market: {bet.market_name}</Typography>
            <Typography variant="body2">Selection: {bet.selection_name}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Odds: {bet.odds}</Typography>
            <Typography variant="body2">Amount: {bet.stack}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Date: {formatDate(bet.created_at)}</Typography>
            <Chip
              label={bet.is_back === "1" ? "Back" : "Lay"}
              sx={{ backgroundColor: bet.is_back === "1" ? '#addbf8' : '#f9c9d4' }}
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">
              Result: {bet.result === "0" ? "Pending" : (bet.result === "1" ? "Win" : "Loss")}
            </Typography>
            <Typography variant="body2">P/L: {bet.p_l}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </StyledCard>
  );

  const renderBetSection = (bets, isMatched) => (
    <Box>
      {bets.map(bet => renderBetCard(bet))}
    </Box>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledBox>
      <StyledTypography variant="h4">
        Bet History
      </StyledTypography>

      <FilterBox>
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <FormControl fullWidth>
          <InputLabel>Win Status</InputLabel>
          <Select
            value={winStatus}
            onChange={(e) => setWinStatus(e.target.value)}
            label="Win Status"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="win">Win</MenuItem>
            <MenuItem value="lose">Lose</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Delete Status</InputLabel>
          <Select
            value={deleteStatus}
            onChange={(e) => setDeleteStatus(e.target.value)}
            label="Delete Status"
          >
            
            <MenuItem value="undelete">Undelete</MenuItem>
            <MenuItem value="delete">Delete</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Bet Type</InputLabel>
          <Select
            value={betType}
            onChange={(e) => setBetType(e.target.value)}
            label="Bet Type"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="match">Match</MenuItem>
            <MenuItem value="session">Session</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Type"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="lay">Lay</MenuItem>
            <MenuItem value="back">Back</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Select Sports</InputLabel>
          <Select
            value={sport}
            onChange={(e) => setSport(e.target.value)}
            label="Select Sports"
          >
            <MenuItem value="1">All</MenuItem>
            <MenuItem value="2">Football</MenuItem>
            <MenuItem value="3">Tennis</MenuItem>
            <MenuItem value="4">Cricket</MenuItem>
            <MenuItem value="5">Live Game</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={fetchBets}>Apply Filters</Button>
      </FilterBox>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      )}

      <Typography variant="h6">Matched Bets</Typography>

      {bets.length > 0 ? renderBetSection(bets) : <Typography>You have no matched bets</Typography>}

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </StyledBox>
  );
};

export default OpenBetsPage;