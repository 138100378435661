import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import {
  Typography,
  Grid,
  Button,
  TextField,
  Switch,
  IconButton,
  CircularProgress,
  LinearProgress,
  Box,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useSnackbar } from '../../hooks/useSnackbar'
import speakText from '../../utils/speechText'
import { betPlaceFancyUrl, betPlaceUrl } from '../../constants'
import { useWebSocketContext } from '../../hooks/useWebsocket'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const StyledContainer = styled('div')(({ theme }) => ({
  padding: '8px',
  borderRadius: theme.shape.borderRadius,
  maxWidth: '600px',
  margin: 'auto',
  background: '',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.appBarColor,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}))

const CompactButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  minWidth: 0,
  width: '24px',
  height: '24px',
}))
const StyledDropShadow = styled('div')(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0 0 0/70%)',
  width: '100%',
  height: '100%',
  zIndex: 1200,
}))

const CountdownCircle = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '120px',
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const CountdownText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  fontSize: '24px',
  fontWeight: 'bold',
  color: '#fff',
}))
const DEFAULT_STAKES = [100, 500, 1000]

const BetModifyComponent = ({
  marketDetails,
  onCancel,
  betType,
  setOddsPositions,
  setFancyPositions,
}) => {
  const location = useLocation()
  const [odds, setOdds] = useState(marketDetails?.odds || 1.0)
  const [stake, setStake] = useState('')
  const [confirmed, setConfirmed] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const { showSnackbar } = useSnackbar()
  const { availableStakes } = useSelector((state) => state.appslice)
  const userDetails = JSON.parse(localStorage.getItem('userDetails') || '{}')
  const token = userDetails.token
  const { subscribeToMarket } = useWebSocketContext()
  const currentMarketId = location.pathname.split('/')[3]
  const currentMatchId = location.pathname.split('/')[4]
  const [betDelayTimer, setBetDelayTimer] = useState(0)

  useEffect(() => { }, [marketDetails])

  const onClear = () => {
    setStake('')
    setOdds(marketDetails?.odds || 1.0)
    setConfirmed(false)
  }

  useEffect(() => {
    setOdds(marketDetails?.odds || 1.0)
  }, [marketDetails?.odds])

  const handleIncrease = (amount) => {
    setStake((prevStake) => Number(prevStake) + amount)
  }

  const handleSubmit = async () => {
    if (confirmed) {
      setLoading(true)
      setBetDelayTimer(5)
      const timer = setInterval(() => {
        setBetDelayTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timer)
            return 0
          }
          return prevTimer - 1
        })
      }, 1000)
      const {
        market: {
          match_id,
          selectionId,
          is_bookmaker,
          market_id,
          runner_json,
          BackSize1,
          LaySize1,
        },
        index,
        side,
      } = marketDetails

      const fancySize = side === 'back' ? BackSize1 : LaySize1
      const oddsSize = 0

      try {
        let payload = {
          stack: Number(stake),
          type: betType === 'fancy' ? 'fancy' : 'market',
          match_id: match_id + '',
          is_bookmaker: is_bookmaker,
          // For the fancy, the market is combination of match id and selection ID
          market_id:
            betType === 'fancy' ? match_id + '_' + selectionId : market_id + '',
          selection_id:
            betType === 'fancy'
              ? selectionId + ''
              : runner_json?.[index]?.id + '',
          is_back: side === 'back' ? 'back' : 'lay',
          odds: Number(odds),
          size: betType === 'fancy' ? fancySize : oddsSize,
          cashout: 0,
          payment_type: 'balance',
        }

        if (betType === 'fancy') {
          // This is needed to verify the fancy bets latest odds.
          payload['fancy_id'] = marketDetails?.market?.market_id + ''
        }

        const url = betType === 'fancy' ? betPlaceFancyUrl : betPlaceUrl
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            accept: 'application/json, text/plain, */*',
            'accept-language': 'en-US,en;q=0.9,en-IN;q=0.8',
            authorization: token,
            'content-type': 'application/json',
          },
          body: JSON.stringify(payload),
          credentials: 'include',
        })
        const data = await response.json()
        const message = data.message

        if (!response.ok) {
          showSnackbar(message, 'error')
        } else {
          showSnackbar(message, 'success')
          subscribeToMarket(currentMarketId, currentMatchId, true)
        }
        // speakText(message);
      } catch (error) {
        console.error('Error placing bet:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleOddsChange = (change) => {
    setOdds((prevOdds) => Math.max(1, Number((prevOdds + change).toFixed(2))))
  }

  const cardBackgroundColor = {
    background: marketDetails?.side === 'lay' ? '#ffcfd9' : '#c8e6fa',
  }

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <StyledDropShadow>
          {betDelayTimer > 0 ? (
            <CountdownCircle>
              <CircularProgress
                variant="determinate"
                value={((5 - betDelayTimer) / 5) * 100}
                size={120}
                thickness={4}
                sx={{
                  color: '#4CAF50',
                  '& .MuiCircularProgress-circle': {
                    strokeLinecap: 'round',
                  },
                }}
              />
              <CountdownText>
                {betDelayTimer}
              </CountdownText>
            </CountdownCircle>
          ) : (
            <CircularProgress size={120} color="secondary" />
          )}
        </StyledDropShadow>
      )}

      <StyledContainer sx={cardBackgroundColor}>
        {/* <Typography variant="h6">{marketDetails.team}</Typography> */}
        <Grid container spacing={0.5} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body2">Odds</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">Stake</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={2}>
                <CompactButton
                  sx={{
                    backgroundColor: '#8a1a24',
                    color: 'white',
                    '&:hover': { backgroundColor: '#FFD700' },
                  }}
                  onClick={() => handleOddsChange(-0.01)}
                >
                  <RemoveIcon fontSize="small" />
                </CompactButton>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  value={odds}
                  onChange={(e) => setOdds(Number(e.target.value))}
                  type="number"
                  inputProps={{
                    step: 0.01,
                    min: 1,
                    style: { fontSize: '0.875rem' }, // This reduces the font size
                  }}
                  sx={{
                    '& .MuiInputBase-input': {
                      padding: '8px',
                      textAlign: 'center',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <CompactButton
                  sx={{
                    backgroundColor: '#8a1a24',
                    color: 'white',
                    '&:hover': { backgroundColor: '#FFD700' },
                  }}
                  onClick={() => handleOddsChange(0.01)}
                >
                  <AddIcon fontSize="small" />
                </CompactButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              value={stake}
              onChange={(e) => setStake(e.target.value)}
              placeholder="Min: 100"
              type="number"
              sx={{ '& .MuiInputBase-input': { padding: '8px' } }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ marginTop: '0px' }}>
          {availableStakes.map((amount) => (
            <Grid item xs={4} key={amount}>
              <StyledButton fullWidth onClick={() => handleIncrease(amount)}>
                +{amount}
              </StyledButton>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={1} style={{ marginTop: '0px' }}>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onClear}>
              Clear
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <StyledButton
              fullWidth
              onClick={handleSubmit}
              disabled={!confirmed}
              sx={{ backgroundColor: '#408140' }}
            >
              Submit
            </StyledButton>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          style={{ marginTop: '8px' }}
        >
          <Typography variant="body2">Confirm bet before placing</Typography>
          <Switch
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
            color="primary"
          />
        </Grid>
      </StyledContainer>
    </div>
  )
}

export default React.memo(
  BetModifyComponent,
  (prev, nextState) => prev !== nextState,
)
