import React from "react";
import { Grid, Typography } from "@mui/material";
import "./CricketGrid.css";
import "./../0_Common/0_Common.css";
import ListCard from "../0_Common/ListCard";

const CricketGrid = ({ data }) => {
  console.log('cricket grid data>>',data)
  return (
    <div>
      <Typography fontSize={20} style={{ fontWeight: 600,marginBottom:'8px'}}>
        Cricket
      </Typography>
      <Grid container spacing={2}>
          <Grid item xs={12} lg={12} >
            <ListCard data={data||[]}  limitProp={6} active={true} />
            <div style={{marginBottom:'8px',backgroundColor:''}}>
            </div>
            {data&&<ListCard data={data}  limitProp={2} active={false} showNonActive='upcoming' />}
          </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(CricketGrid)
