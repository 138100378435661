
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
    Box,
    Typography,
    Paper,
    Button,
    styled,
    useMediaQuery,
    useTheme,
    Modal,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { mockData } from './AccountStatement';

const PnlText = styled(Typography)(({ theme, pnl }) => ({
    color: pnl >= 0 ? theme.palette.success.main : theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
}));
const OrderSummary = () => {
    const params = useParams()

    const itemData = mockData.find(el => String(el.orderId) === params.orderid)

    const totalBets = 1; // 1 to 3 bets
    let remainingPnl = itemData.pnl;
    const detailData = [];

    for (let i = 0; i < totalBets; i++) {
        const isLastBet = i === totalBets - 1;
        const type = Math.random() > 0.5 ? 'Back' : 'Lay';
        const odds = (Math.random() * 2 + 1).toFixed(2);
        const stake = isLastBet ? Math.abs(remainingPnl) : Math.floor(Math.abs(remainingPnl) * (Math.random() * 0.8 + 0.2));
        const profitLoss = isLastBet ? remainingPnl : (type === 'Back' ? 1 : -1) * stake;

        detailData.push({
            selection: itemData.description.eventName.split(' v ')[0], // Use the first team as selection
            status: profitLoss >= 0 ? 'WON' : 'LOST',
            betId: `${itemData.date}${i + 1}`,
            placed: itemData.date,
            type,
            odds: parseFloat(odds),
            stake,
            profitLoss
        });

        remainingPnl -= profitLoss;
    }

    const config = {
        statementFields: [
            { key: 'date', label: 'Date', formatter: (value) => formatDate(value) },
            { key: 'time', label: 'Time', formatter: (value) => formatTime(value) },
            { key: 'actionDisplayName', label: 'Description' },
            { key: 'pnl', label: 'P&L', formatter: (value) => Math.abs(value).toFixed(2), component: PnlText },
            { key: 'creditLimit', label: 'Credit Limit', formatter: (value) => value === null ? '-' : value },
        ],
        detailFields: {
             'Status':'status', 'Bet ID':'betId', 'Placed':'placed', 'Type':'type', 'Odds':'odds', 'Stake':'stake', 'Profit/Loss':'profitLoss',
    },
        summaryFields: [
            { key: 'backSubtotal', label: 'Back subtotal' },
            { key: 'laySubtotal', label: 'Lay subtotal' },
            { key: 'marketSubtotal', label: 'Market subtotal' },
            { key: 'commission', label: 'Commission' },
            { key: 'netMarketTotal', label: 'Net Market Total', isTotal: true },
        ],
    };

    // Helper functions
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    };


    const backSubtotal = detailData.filter(item => item.type === 'Back').reduce((sum, item) => sum + item.profitLoss, 0);
    const laySubtotal = detailData.filter(item => item.type === 'Lay').reduce((sum, item) => sum + item.profitLoss, 0);
    const marketSubtotal = backSubtotal + laySubtotal;
    const commission = 0;
    const netMarketTotal = marketSubtotal - commission;


    return (
        <div style={{padding:'4px 8px 40px 8px'}} >
            <Typography variant='h4'>
                Order Summary
            </Typography>
            <Typography variant='body1'>
                Order ID: {params?.orderid}
            </Typography>

            <Typography variant="subtitle1" color="primary" gutterBottom>
                {itemData.description.eventName} - {itemData.description.marketName}
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                 
                       
                            {Object.entries(config.detailFields).map((field,i) => (
                                 <TableRow>
                                         
                   
                                <TableCell key={field[1]}>{field[0]}</TableCell>  
                                
                                {
                                    detailData.map((bet,index)=>(
                                        <TableCell key={index} >
                                          {bet[field[1]]}
                                        </TableCell>
                                    ))
                                }
                                </TableRow>
                            ))}
                
                    <TableBody>
                        {/* {detailData.map((bet, index) => (
                            <TableRow key={index}>
                               
                                <TableCell>{bet.betId}</TableCell>
                                <TableCell>{formatDate(bet.placed)} {formatTime(bet.placed)}</TableCell>
                                <TableCell>{bet.type}</TableCell>
                                <TableCell>{bet.odds}</TableCell>
                                <TableCell>{bet.stake}</TableCell>
                                <TableCell style={{ color: bet.profitLoss >= 0 ? 'green' : 'red' }}>
                                    {bet.profitLoss.toFixed(2)}
                                </TableCell>
                            </TableRow>
                        ))} */}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={2}>
                {config.summaryFields.map(field => (
                    <Typography key={field.key} variant={field.isTotal ? 'h6' : 'body1'}>
                        {field.label}: {eval(field.key).toFixed(2)}
                    </Typography>
                ))}
            </Box>

        </div>
    )
}

export default OrderSummary