import React from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Avatar,
  styled,
  Container,
  Grid,
  Paper,
  useMediaQuery,
} from '@mui/material';
import {
  Person,
  Email,
  Phone,
  AccountBalance,
  AccessTime,
  Code,
  Edit,
} from '@mui/icons-material';
import theme from '../../utils/customTheme';

const ProfileContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

const ProfilePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const AvatarWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiAvatar-root': {
    width: 120,
    height: 120,
    boxShadow: theme.shadows[3],
  },
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  backgroundColor: theme.palette.buttonSecondaryColor,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: theme.palette.buttonSecondaryColor,
    opacity: 0.9,
  },
}));

const Profile = () => {
  const userdetails = JSON.parse(localStorage.getItem('userDetails') || '{}');
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
  };

  return (
    <ProfileContainer maxWidth="md">
      <ProfilePaper elevation={3}>
        <AvatarWrapper>
          <Avatar src="/path-to-user-image.jpg" alt={userdetails?.name?.toUpperCase()} />
        </AvatarWrapper>
        <Typography component="h1" variant="h4" gutterBottom>
          {userdetails?.name}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InfoItem>
              <AccessTime />
              <Typography variant="body1">
                Last Login: {userdetails?.last_login}
              </Typography>
            </InfoItem>
            <InfoItem>
              <AccountBalance />
              <Typography variant="body1">
                Balance: {userdetails?.balance}
              </Typography>
            </InfoItem>
            <InfoItem>
              <Code />
              <Typography variant="body1">
                Referral Code: {userdetails?.referred_code}
              </Typography>
            </InfoItem>
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoItem>
              <Phone />
              <Typography variant="body1">
                Mobile: {userdetails?.mobile}
              </Typography>
            </InfoItem>
            <InfoItem>
              <Person />
              <Typography variant="body1">
                ID: {userdetails?.id}
              </Typography>
            </InfoItem>
          </Grid>
        </Grid>

        <StyledForm onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="name"
                label="Full Name"
                name="name"
                InputProps={{
                  startAdornment: <Person color="action" />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                InputProps={{
                  startAdornment: <Email color="action" />,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="phone"
                label="Phone Number"
                name="phone"
                InputProps={{
                  startAdornment: <Phone color="action" />,
                }}
              />
            </Grid>
          </Grid>
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            startIcon={<Edit />}
          >
            Update Profile
          </StyledButton>
        </StyledForm>
      </ProfilePaper>
    </ProfileContainer>
  );
};

export default Profile;