import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  availableStakes: [100, 200, 400],
}

const appSlice = createSlice({
  name: 'appslice',
  initialState,
  reducers: {
    updateLoadingStatus: (state, action) => {
      state.isLoading = action.payload
    },
    updateAvailableStakes: (state, action) => {
      const newValue = action.payload.newValue
      const index = action.payload.index
      if (!isNaN(newValue) && newValue > 0) {
        if (index === -1) {
          state.availableStakes = [
            ...state.availableStakes,
            action.payload.newValue,
          ]
        } else {
          const newStakes = [...state.availableStakes]
          newStakes[index] = newValue
          state.availableStakes = newStakes
        }
      }
    },
    deleteAvailableStakes: (state, action) => {
      const index = action.payload.index

      const newStakes = state.availableStakes.filter((_, i) => i !== index)

      state.availableStakes = newStakes
    },
  },
})

export const {
  updateAvailableStakes,
  updateLoadingStatus,
  deleteAvailableStakes,
} = appSlice.actions

export default appSlice.reducer
