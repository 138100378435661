import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({

  marginBottom: theme.spacing(2),
}));

const CardSection = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
}));

const BetRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}));
const StyleHeader = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),

}));
const StyledTypography = styled(Typography)(({ theme, color }) => ({
  fontWeight: 'bold',
  color: theme.palette[color].main,
}));

const OpenBets = () => {
  // Mock data for demonstration
  const openBets = [
    {
      event: 'Man City vs Arsenal',
      market: 'Match Winner',
      runner: 'Man City',
      odds: 1.75,
      stake: 100,
      return: 175,
      exposure: 100,
      date: '2023-04-26 20:00',
    },
    // Add more mock data as needed
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Open Bets
      </Typography>
      {openBets.map((bet, index) => (
        <StyledCard key={index}>
          <StyleHeader>
          <Typography variant="h6" gutterBottom>{bet.event}</Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>{bet.date}</Typography>
          </StyleHeader>
        <div style={{display:'flex'}}>
          <CardSection>
            
            <BetRow>
              <Typography variant="body2">Market:</Typography>
              <Typography variant="body2">{bet.market}</Typography>
            </BetRow>
            <BetRow>
              <Typography variant="body2">Runner:</Typography>
              <Typography variant="body2">{bet.runner}</Typography>
            </BetRow>
            <BetRow>
              <Typography variant="body2">Odds/Price:</Typography>
              <Typography variant="body2">{bet.odds}</Typography>
            </BetRow>
          </CardSection>
          <CardSection>
            <BetRow>
              <Typography variant="body2">Stake:</Typography>
              <StyledTypography variant="body2" color="primary">
                ${bet.stake}
              </StyledTypography>
            </BetRow>
            <BetRow>
              <Typography variant="body2">Return:</Typography>
              <StyledTypography variant="body2" color="success">
                ${bet.return}
              </StyledTypography>
            </BetRow>
            <BetRow>
              <Typography variant="body2">Exposure:</Typography>
              <StyledTypography variant="body2" color="error">
                ${bet.exposure}
              </StyledTypography>
            </BetRow>
          </CardSection>
          </div>
        </StyledCard>
      ))}
    </Box>
  );
};

export default OpenBets;