import React, { useMemo, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, styled } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import theme from '../../utils/customTheme'
import { TAB } from '../../utils/const'
import { store } from '../../store/store'
import { Provider } from 'react-redux'
import { WebSocketProvider } from '../../hooks/useWebsocket'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { RootLayout } from './RootLayout'
import DashboardCarousal from '../DashboardCarousal/DashboardCarousal'
import Menu from '../../component/Menu/Menu'
import SiteRules from '../static/SiteRules'
import ResponsibleGaming from '../static/ResponsibleGaming'
import ExclusionPolicy from '../static/ExclusionPolicy'
import PrivacyPolicy from '../static/PrivacyPolicy'
import ProfitLossPage from '../0_Common/ProfitLoss'
import CommonEventScreen from '../../component/0_Common/CommonEventScreen'
import DepositWithDraw from '../../component/0_Common/DepositWithdraw'
import OpenBets from '../../component/0_Common/OpenBets'
import Profile from '../../component/0_Common/Profile'
import AccountStatement from '../../component/0_Common/AccountStatement'
import ChangePassword from '../../component/0_Common/ChangePassword'
import { SnackbarProvider } from '../../hooks/useSnackbar'
import './app.css'
import NotFound from '../0_Common/NotFound'
import BetHistory from '../0_Common/BetHistory'
import OrderSummary from '../0_Common/OrderSummary'
import Settings from '../0_Common/Settings'
import Login from '../0_Common/Login'
const AppContainer = styled('div')(({ theme }) => ({
  margin: '-8px',
  backgroundColor: theme.palette.backgroundColor,
}))

// Create a context for the current tab
export const TabContext = React.createContext()

function App() {
  const [currentTab, setCurrentTab] = useState(TAB.ALL);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  // Memoize the tab context value
  const tabContextValue = { currentTab, setCurrentTab }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <TabContext.Provider value={tabContextValue}>
          <Router>
            <AppContainer>
              <SnackbarProvider>
                <WebSocketProvider>
                  {/* {isDesktop ? <DesktopLayout /> : <MobileLayout />} */}
                  <Routes>
                    <Route path='/' element={<RootLayout />}>
                      <Route path="/" element={<>{!isDesktop ? <DashboardCarousal />: null}<Menu currentTab={currentTab} /></>} />
                     
                      <Route path="/sports/:event" element={<Menu />} />
                      <Route path="/sports/:event/:eventId/:matchId" element={<CommonEventScreen />} />
                      <Route path="/deposit-withdraw" element={<DepositWithDraw />} />
                      <Route path="/open-bets" element={<OpenBets />} />
                      <Route path="/account-statement" element={<AccountStatement />} />
                      <Route path="/account-statement/:orderid" element={<OrderSummary />} />
                      <Route path="/rules" element={<SiteRules />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/exclusion" element={<ExclusionPolicy />} />
                      <Route path="/responsible" element={<ResponsibleGaming />} />
                      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                      <Route path="/profit-loss" element={<ProfitLossPage />} />
                      <Route path="/profile" element={<Profile />} />
                      <Route path="/bet-history" element={<BetHistory />} />
                      <Route path="/reset-password" element={<ChangePassword />} />
                      <Route path="*" element={<NotFound />} />
                    </Route>
                    <Route path="/login" element={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f5f5f5', height: '100vh'}}><Login /></div>} />
                  </Routes>
                </WebSocketProvider>
              </SnackbarProvider>
            </AppContainer>
          </Router>
        </TabContext.Provider>
      </ThemeProvider>
    </Provider>
  )
}

export default App