import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import MoneyIcon from '@mui/icons-material/Money';
import TimerIcon from '@mui/icons-material/Timer';
import BlockIcon from '@mui/icons-material/Block';

const ResponsibleGaming = () => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Responsible Gaming
        </Typography>

        <Typography paragraph>
          The Site is committed to Responsible Gambling and we take our responsibilities towards our customers very seriously. We aim to provide an environment in which you can bet in a safe, fair and above all responsible manner.
        </Typography>

        <Typography paragraph>
          If you feel you may have a problem when it comes to controlling your gambling, please consider using one of our tools aimed at helping this:
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon>
              <MoneyIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Deposit Limits" 
              secondary="By selecting a deposit limit per day, week or month"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <TimerIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Time Out Facility" 
              secondary="By using our &quot;time out&quot; facility to allow you to suspend your account activity for the following durations - 24 hours, one week, one month or any other period as you may reasonably request up to a maximum of 6 weeks"
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <BlockIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="Self-Exclusion" 
              secondary="Opting for a self-exclusion, the minimum period being six months which means your account will be blocked for a set amount of time. Self-exclusions cannot be undone and may only be unlocked by contacting customer services when the self-exclusion time runs out"
            />
          </ListItem>
        </List>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary">
            We encourage responsible gaming. If you need help, please don't hesitate to use our tools or contact our customer support.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default ResponsibleGaming;