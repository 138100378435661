import * as React from "react";
import { Divider, Typography, Switch,styled, Skeleton } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { useWebSocketSelector } from "../../hooks/useWebsocket";
import { formattedDate } from "../../utils/formattedDate";

function renderLiveTV() {
  return <Typography variant="caption">No preview available</Typography>;
}

const MatchDetailsCard = () => {
  const location = useLocation()
 const currentEventId = location.pathname.split('/')[3]
 const { marketIds } = useWebSocketSelector(
  (state) => state.websocket,
)
  const [liveTV, setLiveTV] = React.useState(false);
const currentMarketData = marketIds[currentEventId]
const currentGameDate =  currentMarketData && formattedDate(currentMarketData.date,'dd MMM yyy')
if(!currentMarketData){
  return <div> <Skeleton  height={'200px'} /></div>
}
  const MetallicCreditCard = styled('div')(({ theme }) => ({
    background: `linear-gradient(
      145deg,
      #2c2c2c 0%,
      #1a1a1a 100%
    )`,
    borderRadius: '15px',
    padding: '20px',
    color: '#e0e0e0',
    position: 'relative',
    overflow: 'hidden',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)',
    
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-50%',
      left: '-50%',
      right: '-50%',
      bottom: '-50%',
      background: `linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.05) 40%,
        rgba(255, 255, 255, 0) 50%,
        rgba(255, 255, 255, 0.05) 60%,
        rgba(255, 255, 255, 0.1) 100%
      )`,
      transform: 'rotate(45deg)',
      zIndex: 1,
      pointerEvents: 'none',
    },
  
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: `repeating-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.05),
        rgba(255, 255, 255, 0.05) 1px,
        transparent 1px,
        transparent 2px
      )`,
      zIndex: 2,
      pointerEvents: 'none',
    },
  
    '& > *': {
      position: 'relative',
      zIndex: 3,
    },
  }));


  // const eventData = {
  //   eventName: "India v/s Pakistan (Test)",
  //   summary: "End of Day 2 - IND 999-9 (Declared)",
  //   ballSummary: ["4", "6", "wd", "nb", "3", "6", "4", "wk", "3lb"],
  //   overOngoing: true,
  // };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  /**
   * Cricket Score Logic Start
   */
  const renderBalls = (ballData) => {
    const classNameForBall =
      Number(ballData) && Number(ballData) < 4
        ? "normal"
        : Number(ballData) && Number(ballData) >= 4
        ? "boundary"
        : "illegal";
    return <div className={classNameForBall}>{ballData}</div>;
  };

  const cricketBoard = (
    <>
      <Typography sx={{ fontSize: "0.8rem", marginBottom: "4px" }}>
        {currentMarketData.summary}
      </Typography>
      <Typography sx={{ fontSize: "0.7rem", marginBottom: "4px" }}>
{currentGameDate.date}
      </Typography>
      {/* <div className="ballsContainer">
        {eventData?.ballSummary?.map((item) => {
          return renderBalls(item);
        })}
      </div> */}
    </>
  );

  /**
   * Cricket Score Logic End
   */

  /**
   * 
   * @returns Event ScoreBoard
   */
  const renderScoreBoard = () => {
    return cricketBoard
  };

  return (
    <MetallicCreditCard>
    
      <div className="matchDetailsHeader">
        <Typography sx={{ fontSize: "1rem" }}>{currentMarketData.match_name}</Typography>
        <div className="matchDetailsHeader-right">
          <Switch
            sx={{
              "& .MuiSwitch-track": {
                backgroundColor: "grey",
              },
              "& .Mui-checked + .MuiSwitch-track": {
                backgroundColor: "black", // Track color when the switch is on
              },
              "& .MuiSwitch-thumb": {
                backgroundColor: "black", // Thumb color
              },
            }}
            {...label}
            size="small"
            onChange={() => setLiveTV(!liveTV)}
            checked={liveTV}
          />
          <Typography sx={{ fontSize: "12px" }}>Watch Live</Typography>
        </div>
      </div>
      <Divider />
      {liveTV ? renderLiveTV() : renderScoreBoard()}
  
    </MetallicCreditCard>
  );
};

export default React.memo(MatchDetailsCard);
