import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../../utils/customTheme';

const OpenBetsPage = () => {
  // Mock data for Current Bets (renamed to Open Bets)
  const mockData = [
    { eventName: 'Football Match A', nation: 'England', userRate: 1.95, amount: 100, placeDate: '2024-08-06 14:30' },
    { eventName: 'Horse Race B', nation: 'USA', userRate: 2.50, amount: 50, placeDate: '2024-08-06 15:15' },
    { eventName: 'Tennis Tournament C', nation: 'France', userRate: 1.75, amount: 200, placeDate: '2024-08-06 16:00' },
  ];

  return (
    <Box sx={{ bgcolor: '#f0f0f0', minHeight: '100vh', padding: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Open Bets</Typography>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ bgcolor: theme.palette.buttonSecondaryColor, color: 'white' }}
        >
          <Typography>Unmatched Bets</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Event Name</TableCell>
                  <TableCell>Nation</TableCell>
                  <TableCell>User Rate</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Place Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mockData.map((bet, index) => (
                  <TableRow key={index}>
                    <TableCell>{bet.eventName}</TableCell>
                    <TableCell>{bet.nation}</TableCell>
                    <TableCell>{bet.userRate}</TableCell>
                    <TableCell>{bet.amount}</TableCell>
                    <TableCell>{bet.placeDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ bgcolor: theme.palette.buttonSecondaryColor, color: 'white', mt: 2 }}
        >
          <Typography>Matched Bets</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>You have no matched bets</Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default OpenBetsPage;