import * as React from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  Tabs,
  Tab,
  useTheme,
  styled,
  Collapse,
  Skeleton,
} from "@mui/material";
import BetModifyComponent from "./BetModifyComponent";
import { useWebSocketContext, useWebSocketSelector } from "../../hooks/useWebsocket";
import { useLocation } from "react-router-dom";

const CardRoot = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[0],
  padding: theme.spacing(1),
  marginBottom: theme.spacing(4),
}));

const HeaderTypography = styled(Typography)(({ variant, theme }) => ({
  fontSize: variant === 'title' ? theme.typography.pxToRem(14) : theme.typography.pxToRem(10),
}));

const SuspendedOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgb(0 0 0 / 64%)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  borderRadius: '10px',
}));

const SuspendedText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const SideTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  textAlign: 'center',
  fontWeight: 600,
  color: theme.palette.text.primary,

}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontSize: theme.typography.pxToRem(12),
}));


const TeamTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  marginTop: theme.spacing(1.25),
  fontWeight: 700,
}))

const StyledButton = styled(Button)(({ theme, variant }) => ({
  fontSize: theme.typography.pxToRem(14),
  alignItems: 'center',
  color: theme.palette.text.primary,
  textAlign: 'center',
  padding: '2px 2px 2px !important',
  marginTop: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 0,
  width: '95%',
  background: variant === 'back'
    ? theme.palette.backButtonColor
    : theme.palette.layButtonColor,
  '&:hover': {
    background: variant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:active': {
    background: variant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
  '&:focus': {
    background: variant === 'back' ? theme.palette.backButtonColor : theme.palette.layButtonColor,
  },
}))

const PLTypography = styled(Typography)(({ theme, isPositive }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 600,
  color: isPositive ? 'green' : 'red',
  backgroundColor: isPositive ? '#e6ffe6' : '#ffe6e6',
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  marginLeft: 'auto',
}))

const TitleContainer = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1),
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  marginBottom: theme.spacing(1),
}))

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 700,
  letterSpacing: '0.5px',
}))

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(12),
  fontWeight: 500,
  marginTop: theme.spacing(0.5),
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 600,
  textAlign: 'center',
  color: theme.palette.text.primary,
}))
const FancyCard = React.memo(() => {
  const theme = useTheme();
  const location = useLocation()
  const [openBetModify, setOpenBetModify] = React.useState(null);
  const { fancyValues } = useWebSocketContext();
  const { marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )
  const { updatedMarketDetails } = useWebSocketContext()
  const currentGameIdFromLocation = location.pathname.split('/')[3]
  // console.log('marketIds MATCHODDS CARD>>', marketIds)
  const matchOddsData = marketIds[currentGameIdFromLocation]

  const toggleBetModify = (index, side, odds) => {
    setOpenBetModify(prev => {
      if (prev?.index === index && prev.side === side) {
        // If clicking the same button, close the modal
        return null;
      } else {
        // If clicking a different button or opening a new modal, update or create new state
        return { isOpen: true, side, odds, market: fancyValues[index], index };
      }
    });
  }

  const closeBetModify = React.useCallback(() => {
    setOpenBetModify(null);
  }, []);
  // console.log('fancyValues', fancyValues)

  if (fancyValues?.length === 0) {
    return <Skeleton animation="wave" height={'600px'} sx={{ transformOrigin: '0 0 !important' }} />
  }

  return (
    <CardRoot>
      <Grid container spacing={0.2}>
        <Grid item xs={12}>
          <TitleContainer>
            <TitleTypography variant="h6">Fancy</TitleTypography>
            <SubtitleTypography variant="subtitle2">
              Min: {matchOddsData?.min_stack} &nbsp; Max: {matchOddsData?.max_stack}
            </SubtitleTypography>
          </TitleContainer>
        </Grid>
        <Grid item xs={7}>

        </Grid>
        {['Lay', 'Back'].map((side) => (
          <Grid item xs={2.5} key={side} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <SideTypography sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <StyledTypography>{side}</StyledTypography>
            </SideTypography>
          </Grid>
        ))}
      </Grid>
      <Divider />

      <Grid container spacing={0.2}>
        {fancyValues && fancyValues.map((fancy, index) => {
          const updatedRunnerJSON = updatedMarketDetails?.markets?.find(i => i?.market_id === fancy?.market_id)?.runner_json;

          return (
            <React.Fragment key={fancy.fancyId}>
              <Grid item xs={7} style={{ display: 'flex', alignItems: 'center' }}>
                <TeamTypography>{fancy.RunnerName}</TeamTypography>
                {updatedRunnerJSON && updatedRunnerJSON.pl !== undefined && (
                  <PLTypography isPositive={updatedRunnerJSON.pl >= 0}>
                    {updatedRunnerJSON.pl >= 0 ? '+' : '-'}
                    {Math.abs(updatedRunnerJSON.pl)}
                  </PLTypography>
                )}
              </Grid>
              <Grid item xs={5} style={{ position: 'relative' }}>
                <Grid container>
                  <Grid item xs={6}>
                    <StyledButton
                      variant="lay"
                      onClick={() => toggleBetModify(index, 'lay', fancy.LayPrice1)}
                      sx={{ marginBottom: '3.1px', borderRadius: '8px 0px 0px 8px ', padding: '8px 0 8px 0 !important' }}
                      disabled={fancy.GameStatus === 'SUSPENDED'}
                    >
                      <Typography fontSize={'12px'} fontWeight={600}>{fancy.LayPrice1}</Typography>
                      <Typography fontSize={'9px'} fontWeight={400}>{fancy.LaySize1}</Typography>
                    </StyledButton>
                  </Grid>
                  <Grid item xs={6}>
                    <StyledButton
                      variant="back"
                      onClick={() => toggleBetModify(index, 'back', fancy.BackPrice1)}
                      sx={{ marginBottom: '3.1px', borderRadius: '0 8px 8px 0 ', padding: '8px 0 8px 0 !important' }}
                      disabled={(fancy.GameStatus === 'SUSPENDED' || fancy.GameStatus === 'BALL RUNNING')}
                    >
                      <Typography fontSize={'12px'} fontWeight={600}>{fancy.BackPrice1}</Typography>
                      <Typography fontSize={'9px'} fontWeight={400}>{fancy.BackSize1}</Typography>
                    </StyledButton>
                  </Grid>
                </Grid>
                {(fancy.GameStatus === 'SUSPENDED' || fancy.GameStatus === 'BALL RUNNING') && (
                  <SuspendedOverlay>
                    <SuspendedText variant="body2">{fancy.GameStatus}</SuspendedText>
                  </SuspendedOverlay>
                )}
              </Grid>
              <Grid item xs={12}>
                <Collapse in={openBetModify?.index === index}>
                  {openBetModify?.index === index && (
                    <BetModifyComponent
                      marketDetails={openBetModify}
                      onCancel={closeBetModify}
                      betType={'fancy'}
                    />
                  )}
                </Collapse>
              </Grid>
              {index < fancyValues.length - 1 && <Divider style={{ width: '100%' }} />}
            </React.Fragment>
          )
        })}
      </Grid>
    </CardRoot>
  );
});

export default FancyCard;