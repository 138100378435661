// src/components/Menu/Menu.js
import React, { useEffect } from 'react'
import './Menu.css'
import CricketGrid from '../Cricket/CricketGrid'
import FootballGrid from '../Football/FootballGrid'
import CasinoGrid from '../Casino/CasinoGrid'
import TennisGrid from '../Tennis/TennisGrid'
import HomeGrid from '../Home/HomeGrid'
import { TAB } from '../../utils/const'
import { LinearProgress,Skeleton} from '@mui/material'
import { useWebSocketContext, useWebSocketSelector } from '../../hooks/useWebsocket'
import { useParams } from 'react-router-dom'
const Menu = ({ currentTab }) => {
  const { event } = useParams()
  
  const { status, marketIds } = useWebSocketSelector(
    (state) => state.websocket,
  )

  
  const { connect } = useWebSocketContext()
const marketIdArray = Object.values(marketIds || {});
  // useEffect(() => {
  //   if (status !== 'CONNECTED') {
  //     connect()
  //   }
  // }, [status, connect])

  const getGameData = (gameType) => {
    return gameType === TAB.ALL
      ? marketIdArray
      : marketIdArray?.filter(
          i => i.sport_name.toLowerCase() === gameType.split('/')[2].toLowerCase(),
        )
  }
  // debugger

  if (status !== 'CONNECTED') {
    return (
      <>
        <div style={{width:'100%'}}>
        <LinearProgress/>
        </div>
      </>
    )
  }

  return (
    <div>
      {marketIdArray.length> 0 ? (
        <div className="tab-content">
          {event === undefined && <HomeGrid/>}
          {event === 'CRICKET' && (
            <CricketGrid data={getGameData(TAB.CRICKET)} />
          )}
          {event === 'FOOTBALL' && (
            <FootballGrid data={getGameData(TAB.FOOTBALL)} />
          )}
          {event === 'CASINO' && <CasinoGrid data={getGameData(TAB.CASINO)} />}
          {event === 'TENNIS' && <TennisGrid data={getGameData(TAB.TENNIS)} />}
        </div>
      ) : (
        <div style={{padding:'0 8px 0 8px'}}>
          {Array(5).fill(0).map((_,index) => (
            <Skeleton key={index} height={200} width={'100%'} variant='rectangular' animation='wave' sx={{marginBottom:'8px'}}
             />
          ))}
        </div>
      )}
    </div>
  )
}

export default Menu
