import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  styled,
  CircularProgress
} from '@mui/material';
import { useSnackbar } from '../../hooks/useSnackbar';
import theme from '../../utils/customTheme';
import { UpdatePasswordUrl } from '../../constants';

const ChangePasswordContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(5, 3),
}));

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  background: theme.palette.buttonSecondaryColor,
  '&:hover': {
    background: theme.palette.buttonSecondaryHoverColor,
  },
}));

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [generalError, setGeneralError] = useState(null);
  const { showSnackbar } = useSnackbar();

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/.test(password);
    const hasLetter = /[a-zA-Z]/.test(password);
    return password.length >= minLength && hasNumber && hasLetter;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  useEffect(() => {
    const newErrors = {};
    if (formData.newPassword && !validatePassword(formData.newPassword)) {
      newErrors.newPassword = "Password must be at least 8 characters long and contain at least 1 number and 1 letter";
    }
    if (formData.confirmPassword && formData.newPassword !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    setErrors(newErrors);
  }, [formData.newPassword, formData.confirmPassword]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(errors).length > 0 || !validatePassword(formData.newPassword)) {
      setGeneralError("Please correct the errors before submitting");
      return;
    }

    setLoading(true);
    setGeneralError(null);

    try {
      const response = await fetch(UpdatePasswordUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': JSON.parse(localStorage.getItem('userDetails') || '{}')?.token,
        },
        body: JSON.stringify({
          old_password: formData.currentPassword,
          new_password: formData.newPassword,
          confirm_password: formData.confirmPassword
        }),
        credentials: 'include'
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to change password');
      }

      showSnackbar('Password changed successfully', 'success');
      setFormData({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (error) {
      console.error('Error changing password:', error);
      setGeneralError(error.message || 'An error occurred while changing the password');
      showSnackbar(error.message || 'Failed to change password', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ChangePasswordContainer>
      <Typography component="h1" variant="h5">
        Change Password
      </Typography>
      <StyledForm onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="currentPassword"
          label="Current Password"
          type="password"
          id="currentPassword"
          autoComplete="current-password"
          value={formData.currentPassword}
          onChange={handleChange}
          required
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="newPassword"
          label="New Password"
          type="password"
          id="newPassword"
          value={formData.newPassword}
          onChange={handleChange}
          required
          error={!!errors.newPassword}
          helperText={errors.newPassword}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="confirmPassword"
          label="Confirm New Password"
          type="password"
          id="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          required
          error={!!errors.confirmPassword}
          helperText={errors.confirmPassword}
        />
        <StyledButton
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          disabled={loading || Object.keys(errors).length > 0}
        >
          {loading ? <CircularProgress size={24} /> : 'Change Password'}
        </StyledButton>
      </StyledForm>
      {generalError && (
        <Alert severity="error" sx={{ mt: 2, width: '100%', maxWidth: 400 }}>
          {generalError}
        </Alert>
      )}
    </ChangePasswordContainer>
  );
};

export default ChangePassword;