import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  List, 
  ListItem, 
  ListItemText,
  Divider,
  Paper
} from '@mui/material';

const SiteRules = () => {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, my: 4 }}>
        <Typography variant="h3" gutterBottom>
          THE SITE RULES AND REGULATIONS
        </Typography>

        <Typography variant="h4" gutterBottom>
          PART A - INTRODUCTION
        </Typography>

        <Typography variant="h5" gutterBottom>
          Use and interpretation
        </Typography>

        <Typography paragraph>
          These Rules and Regulations ("Rules") are part of the Site's terms and conditions.
        </Typography>

        <Typography paragraph>
          The Rules apply to all bets placed on this online betting platform ("Site"). The Rules consist of the following:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="This INTRODUCTION section (Part A);" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The GENERAL RULES (set out in Part B below); and" />
          </ListItem>
          <ListItem>
            <ListItemText primary="The SPECIFIC SPORTS RULES (set out in Part C below - these apply to certain sports)." />
          </ListItem>
        </List>

        <Typography paragraph>
          The General Rules apply to all bets unless stated otherwise in the Specific Sports Rules. If there is any inconsistency between the Specific Sports Rules and the General Rules, the Specific Sports Rules shall prevail.
        </Typography>

        <Typography paragraph>
          The rules governing how markets are offered, managed and/or settled are not the same for every market on each product. In certain circumstances, a bet that is settled as a winner on one product may be settled as a loser on the other product (and vice versa). Additionally, different settlement rules may apply so that, for example, bets that are a winner on one product may be settled as a dead heat or be voided on the other product. Customers must ensure that they familiarise themselves with the relevant rules that apply to the bets that they place on the Site.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Customer responsibility
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="Customers should make themselves aware of all of the Rules affecting any market on which they wish to place a bet." />
          </ListItem>
          <ListItem>
            <ListItemText primary="In particular, customers who use the &quot;one-click&quot; option to place bets are solely responsible for their actions and the Site shall have no liability to such customers for any errors made by customers when using this option." />
          </ListItem>
        </List>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h4" gutterBottom>
          PART B - GENERAL RULES
        </Typography>

        <Typography variant="h5" gutterBottom>
          Matters beyond the Site's reasonable control and malfunctions
        </Typography>

        <Typography paragraph>
          The Site is not liable for any loss or damage you may suffer because of any: act of God; power cut; trade or labour dispute; act, failure or omission of any government or authority; obstruction or failure of telecommunication services; or any other delay or failure caused by a third party or otherwise outside of our control. In such an event, the Site reserves the right to cancel or suspend access to the Site without incurring any liability.
        </Typography>

        <Typography paragraph>
          The Site is not liable for the failure of any equipment or software howsoever caused, wherever located or administered, or whether under its direct control or not, that may prevent the operation of the Site.
        </Typography>

        <Typography paragraph>
          In the event of a technological failure or error which is apparent to the customer, the customer is obliged to notify the Site of such failure/error immediately. If the customer continues to place a bet in these circumstances, they shall take reasonable action to minimise any potential loss. In the absence of such action, the Site reserves the right to void a bet.
        </Typography>

        {/* Continue with the rest of Part B... */}

        <Divider sx={{ my: 4 }} />

        <Typography variant="h4" gutterBottom>
          PART C - SPECIFIC SPORTS RULES
        </Typography>

        <Typography variant="h5" gutterBottom>
          Cricket
        </Typography>

        <Typography variant="h6" gutterBottom>
          General
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="If a ball is not bowled during a competition, series or match then all bets will be void except for those on any market that has been unconditionally determined (e.g. in the 'Completed Match' market)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If a match is shortened by weather, all bets will be settled according to the official result (including for limited overs matches, the result determined by the Duckworth Lewis method)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="In the event of a match being decided by a bowl-off or toss of the coin, all bets will be void except for those on markets that have been unconditionally determined." />
          </ListItem>
        </List>

        <Typography variant="h6" gutterBottom>
          Test matches
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="If a match starts but is later abandoned for any reason other than weather (which may include but is not limited to: dangerous or unplayable wicket or outfield; pitch vandalism; strike or boycott; crowd protests/violence; stadium damage; acts of terrorism; and acts of God), the Site reserves the right to void all bets, except for those on markets that have been unconditionally determined." />
          </ListItem>
          <ListItem>
            <ListItemText primary="If the match is not scheduled to be completed within five days after the original scheduled completion date, then all bets on markets for this event will be void, except for bets on any markets that have been unconditionally determined." />
          </ListItem>
        </List>

        {/* Continue with the rest of Part C... */}

      </Paper>
    </Container>
  );
};

export default SiteRules;