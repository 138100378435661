import React, { useState, useRef, useEffect } from 'react';
import { Card, styled, Typography, IconButton } from '@mui/material';
import { Check, Settings as SettingIcon } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAvailableStakes, updateAvailableStakes } from '../../store/appSlice';

const StakesBox = styled('div')(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.primary.main : theme.palette.buttonSecondaryColor,
  padding: '4px 10px 4px 10px',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent:'space-between',
  cursor: 'pointer',
}));

function Settings() {
  const [stakes, setStakes] = useState([100, 200, 300, 500]);
  const [isAddActive, setIsAddActive] = useState(false);
  const [stakeValue, setStakeValue] = useState('');
  const [editIndex, setEditIndex] = useState(-1);
  const inputRef = useRef(null);
const {availableStakes}=useSelector(state=>state.appslice)
const dispatch = useDispatch()

console.log(availableStakes)
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddActive, editIndex]);

  const handleIsActive = (index) => {
    console.log('handleIsActive',index)
    if (editIndex === index) {
      setEditIndex(-1);
    } else {
      setEditIndex(index);
      setStakeValue(availableStakes[index].toString());
    }
  };

  const handleInputUpdate = (e, index) => {
    e.stopPropagation();
    const newValue = parseInt(stakeValue, 10);
    if (!isNaN(newValue)) {
      dispatch(updateAvailableStakes({ newValue, index }));
    }
    setIsAddActive(false);
    setEditIndex(-1);
  };

  const handleDelete = (e, index) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(deleteAvailableStakes({ index }));
    setEditIndex(-1);
  };

  return (
    <div style={{ padding: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <SettingIcon />
        <Typography variant='h6'>Settings</Typography>
      </div>
      <Card style={{ padding: 8, marginTop: 10 }}>
        <Typography variant='body1'>Edit Stakes</Typography>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10, flexWrap: 'wrap', width: '90vw' }}>
          {availableStakes.map((el, index) => (
            <StakesBox key={index} isActive={editIndex === index} onClick={() => handleIsActive(index)}>
              {editIndex === index ? (
                <>
                  <input
                    value={stakeValue}
                    ref={inputRef}
                    onChange={(e) => setStakeValue(e.target.value)}
                    style={{ maxWidth: '30px', backgroundColor: 'transparent', border: 0, color: 'white' }}
                    onBlur={(e) => {
                      if (!e.relatedTarget || !e.relatedTarget.closest('.delete-button')) {
                        handleInputUpdate(e, index);
                      }
                    }}
                  />
                  <IconButton 
                    size="small" 
                    onClick={(e) => handleInputUpdate(e, index)}
                    sx={{
                      backgroundColor: '#4caf50',
                      '&:hover': { backgroundColor: '#45a049' },
                      borderRadius: '4px',
                      padding: '4px',
                      marginLeft: '4px'
                    }}
                  >
                    <Check fontSize="small" sx={{color:'white', fontSize: '16px'}}/>
                  </IconButton>
                  <IconButton 
                    size="small" 
                    className="delete-button"
                    onClick={(e) => handleDelete(e, index)}
                    sx={{
                      backgroundColor: '#ff5252',
                      '&:hover': { backgroundColor: '#ff1744' },
                      borderRadius: '4px',
                      padding: '4px',
                      marginLeft: '4px'
                    }}
                  >
                    <CloseIcon fontSize="small" sx={{color:'white', fontSize: '16px'}} />
                  </IconButton>
                </>
              ) : (
                <>
                  {el}
                  <EditIcon sx={{ fontSize: '0.9rem', marginLeft: '5px' }} />
                </>
              )}
            </StakesBox>
          ))}
          {isAddActive ? (
            <StakesBox>
              <input
                value={stakeValue}
                ref={inputRef}
                onChange={(e) => setStakeValue(e.target.value)}
                style={{ maxWidth: '30px', backgroundColor: 'transparent', border: 0, color: 'white' }}
                onBlur={(e) => handleInputUpdate(e, -1)}
              />
            </StakesBox>
          ) : (
            <StakesBox onClick={() => setIsAddActive(true)}>
              <AddIcon sx={{ fontSize: '0.9rem' }} />
            </StakesBox>
          )}
        </div>
      </Card>
    </div>
  );
}

export default Settings;