import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { BannerListUrl } from "../../constants";

const CarouselContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  width: "100%",
  position: "relative",
  touchAction: "pan-y",
  marginTop: '10px',
}));

const CarouselInner = styled(Box)(({ theme, currentSlide }) => ({
  display: "flex",
  transition: "transform 0.5s ease-in-out",
  transform: `translateX(${-currentSlide * 100}%)`,
}));

const Slide = styled(Box)(({ theme }) => ({
  flex: "0 0 100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "none",
}));

const ShinyCard = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  maxWidth: "95%",
  maxHeight: "auto",
  borderRadius: theme.shape.borderRadius * 4,
  marginBottom: '10px',
  cursor: 'pointer',
  boxShadow: theme.shadows[10],
  '&::before': {
    content: '""',
    position: "absolute",
    top: 0,
    left: -100,
    width: "50%",
    height: "100%",
    background: "linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 100%)",
    transform: "skewX(-25deg)",
    transition: "0.5s",
  },
  '&:hover::before': {
    left: "125%",
  },
}));

const StyledImg = styled('img')({
  width: "100%",
  height: "auto",
  display: "block",
});

const DashboardCarousal = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [slides, setSlides] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const slideRef = useRef();
  const slides = [
    { id: 1, img: 'banner.jpg' },
    { id: 2, img: 'banner2.jpg' },
  ];

  // useEffect(() => {
  //   const fetchSlides = async () => {
  //     try {
  //       const response = await fetch(BannerListUrl, {
  //         method: "POST",
  //         headers: {
  //           "accept": "application/json, text/plain, */*",
  //           "accept-language": "en-US,en;q=0.9,en-IN;q=0.8",
  //           'Authorization': JSON.parse(localStorage.getItem('userDetails') || '{}').token || '',
  //           "content-type": "application/json",
  //         },
  //         body: JSON.stringify({}),
  //         credentials: "include",
  //       });

  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }

  //       const data = await response.json();
  //       setSlides(data?.data?.filter(item => item.img.includes('banner')) || []);
  //       setLoading(false);
  //     } catch (error) {
  //       setError(error);
  //       setLoading(false);
  //     }
  //   };

  //   fetchSlides();
  // }, []);

  const handleTouchStart = (e) => {
    slideRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (slideRef.current === null) {
      return;
    }

    const diff = slideRef.current - e.touches[0].clientX;

    if (Math.abs(diff) > 5) {
      setCurrentSlide((prevSlide) =>
        diff > 0
          ? (prevSlide + 1) % slides.length
          : (prevSlide - 1 + slides.length) % slides.length
      );
    }

    slideRef.current = null;
  };

  useEffect(() => {
    if (slides && slides.length > 0) {
      const interval = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [slides]);

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;
  if (!slides || slides.length === 0) return null;

  return (
    <CarouselContainer
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <CarouselInner currentSlide={currentSlide}>
        {slides.map((slide, index) => (
          <Slide key={index}>
            <ShinyCard>
              <StyledImg
                src={`${slide.img}`}
                // src={`${process.env.REACT_APP_FRONTEND_HOST}/uploads/${slide.img}`}
                alt={`Banner ${slide.id}`}
              />
            </ShinyCard>
          </Slide>
        ))}
      </CarouselInner>
    </CarouselContainer>
  );
};

export default DashboardCarousal;